import io from "socket.io-client";

const ENDPOINT = "https://api.mindmera.com";
// const ENDPOINT = "http://localhost:5000";

var options = {
  transports: ["websocket"],
};

const socket = io(ENDPOINT, options);

export default socket;
