import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  VisuallyHiddenInput,
  useToast,
} from "@chakra-ui/react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { BsStopBtnFill } from "react-icons/bs";
import Lottie from "react-lottie";
import vmsg from "vmsg";
import { ChatState } from "../Context/ChatProvider";
import animationData from "../animation/typing.json";
import Divider from "../assets/img/Divider.png";
import EmojiIcon from "../assets/img/emoticon.png";
import socket from "../components/miscellaneous/socket";
import { getSender, getSenderFull } from "../config/ChatLogics";
import ScrollableChat from "./ScrollableChat";
import ChatProfileModal from "./miscellaneous/ChatProfileModal";
import UpdateGroupChatModal from "./miscellaneous/UpdateGroupChatModal";
import "./style.css";

import AttachmentIcon from "../assets/icons/attach.png";
import MicIcon from "../assets/icons/microphone-black-shape (1).png";
import SendIcon from "../assets/icons/send.png";

import { Mention, MentionsInput } from "react-mentions";

import mentionsInputStyle from "./miscellaneous/mentionInputStyle";
import { S3_BUCKET, bucket } from "../config";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  renderSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

var selectedChatCompare;

const recorder = new vmsg.Recorder({
  wasmURL: "https://unpkg.com/vmsg@0.3.0/vmsg.wasm",
});

const SingleChat = ({ fetchAgain, setFetchAgain }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [messageNotif, setMessageNotif] = useState([]);
  // const [socketConnected, setSocketConnected] = useState(false);
  const [typing, setTyping] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  const [isRecording, setIsRecording] = useState(false);

  const [selectedMessage, setSelectedMessage] = useState();

  const [users, setUsers] = useState([]);

  const [isTabActive, setIsTabActive] = useState(true);

  const textareaRef = useRef(null);

  const docInputRef = useRef(null);
  const imageInputRef = useRef(null);
  const videoInputRef = useRef(null);
  const audioInputRef = useRef(null);

  const {
    user,
    selectedChat,
    setSelectedChat,
    notification,
    setNotification,
    activeUsers,
    setActiveUsers,
    socketConnected,
    chats,
    setChats,
  } = ChatState();
  const toast = useToast();
  useEffect(() => {
    socket.on("activeUsersUpdate", (updatedActiveUsers) => {
      const uniqueIds = {};

      const uniqueUsers = updatedActiveUsers.filter((user) => {
        if (!uniqueIds[user._id]) {
          uniqueIds[user._id] = true;
          return true;
        }
        return false;
      });

      setActiveUsers(uniqueUsers);
    });

    socket.on("typing", () => setIsTyping(true));
    socket.on("stop typing", () => setIsTyping(false));

    return () => {
      socket.disconnect();
    };
  }, []);

  const fetchmessages = async () => {
    if (!selectedChat) return;

    try {
      const config = {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      };
      setLoading(true);
      const { data } = await axios.get(
        "/api/message/" + selectedChat._id,
        config
      );
      setMessages(data);
      setLoading(false);
      socket.emit("join chat", selectedChat._id);
    } catch (error) {
      toast({
        title: "Error Occured",
        description: "Failed To Load Messages",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };
  const addReadReceipts = async () => {
    if (!selectedChat) return;
    try {
      const chatRecords = [...chats];
      const index = chatRecords.findIndex(
        (record) => record._id === selectedChat._id
      );
      if (index !== -1) {
        chatRecords[index].messageCount = 0;
        setChats(chatRecords);
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };
      axios
        .post(
          "/api/message/add-read-receipts",
          {
            chatId: selectedChat._id,
          },
          config
        )
        .then((res) => {});
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === "visible");
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    fetchmessages();
    addReadReceipts();
    selectedChatCompare = selectedChat;
    updateUsers();
    setSelectedMessage(null);
  }, [selectedChat]);

  const updateUsers = () => {
    if (selectedChat) {
      const newUsers = selectedChat.users.map((user) => {
        return {
          id: user._id,
          display: user.name,
        };
      });
      setUsers(newUsers);
    }
  };

  const showNotification = (notif) => {
    if (Notification.permission === "granted") {
      if (messageNotif.includes(notif._id)) {
        return;
      }

      setMessageNotif([...messageNotif, notif._id]);

      var content = notif.chat.isGroupChat
        ? "New Message in " + notif.chat.chatName
        : "New Message from " + getSender(user, notif.chat.users);
      new Notification("Mind Mera", {
        body: content,
      });
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          var content = notif.chat.isGroupChat
            ? "New Message in " + notif.chat.chatName
            : "New Message from " + getSender(user, notif.chat.users);
          new Notification("Mind Mera", {
            body: content,
          });
        }
      });
    }
  };

  const fetchChats = async () => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      };
      const { data } = await axios.get("/api/chat", config);
      setChats(data);
    } catch (error) {
      toast({
        title: "Failed fectching Chat",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };

  useEffect(() => {
    const handleNewMessage = (newMessageReceived) => {
      console.log(newMessageReceived);
      if (
        document.visibilityState === "visible" &&
        selectedChatCompare &&
        selectedChatCompare._id === newMessageReceived.chat._id
      ) {
        setMessages((prevMessages) => [...prevMessages, newMessageReceived]);
        addReadReceipts();
      } else {
        if (
          !notification.includes(newMessageReceived) &&
          !messageNotif.includes(newMessageReceived._id)
        ) {
          showNotification(newMessageReceived);
        }
        setMessageNotif([...messageNotif, newMessageReceived._id]);
        setNotification([newMessageReceived, ...notification]);

        fetchChats();
      }
    };

    socket.on("message recieved", handleNewMessage);

    return () => {
      socket.off("message recieved", handleNewMessage);
    };
  }, [
    notification,
    messageNotif,
    fetchChats,
    showNotification,
    addReadReceipts,
    setNotification,
  ]);

  const openDocumentInput = () => {
    docInputRef.current.click();
  };
  const openImageInput = () => {
    imageInputRef.current.click();
  };
  const openVideoInput = () => {
    videoInputRef.current.click();
  };
  const openAudioInput = () => {
    audioInputRef.current.click();
  };

  function isSupportedFileType(file, fileType) {
    const supportedFileTypes = {
      image: ["image/jpeg", "image/png", "image/jpg"],
      audio: ["audio/mp3", "audio/mpeg"],
      video: ["video/mp4", "video/mov"],
      document: ["*/*"], // Allow any MIME type for documents
    };

    if (supportedFileTypes[fileType]) {
      // If the fileType is not "document", perform regular check
      if (fileType !== "document") {
        return supportedFileTypes[fileType].includes(file.type);
      } else {
        return true; // Allow any MIME type for documents
      }
    }
    return false;
  }

  const handlePaste = (event) => {
    event.preventDefault();
    const items = (event.clipboardData || event.originalEvent.clipboardData)
      .items;

    for (const item of items) {
      if (item.type.indexOf("image") !== -1) {
        const blob = item.getAsFile();
        upload(blob, "image");
      }
    }
  };

  const upload = async (file, type) => {
    setLoading(true);

    const description = `please select ${type}`;
    if (file === undefined) {
      toast({
        title: "File Error",
        description: description,
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setLoading(false);
      return;
    }

    if (isSupportedFileType(file, type)) {
      const timestamp = new Date().toISOString().replace(/[^a-zA-Z0-9]/g, "");
      const randomString = Math.random().toString(36).substring(2, 8);
      const uniqueFileName = `${timestamp}_${randomString}_${file.name}`;

      const params = {
        ACL: "public-read",
        Body: file,
        Bucket: S3_BUCKET,
        Key: "chat_files/" + selectedChat._id + "/" + uniqueFileName,
      };

      bucket.upload(params, (err, data) => {
        if (err) {
          console.error("Error uploading file:", err);
        } else {
          sendMessage(data.Location, type);
          setLoading(false);
        }
      });
    } else {
      toast({
        title: "Image Error",
        description: description,
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setLoading(false);
    }
  };

  const updateMessage = (data) => {
    const foundMessageIndex = messages.findIndex(
      (message) => message.randomId === data.randomId
    );
    // const foundMessageIndex = messages.findIndex(
    //   (message) =>
    //     message.sender._id === data.sender._id &&
    //     message.messageType === data.messageType &&
    //     message.content === data.content &&
    //     message.chat._id === data.chat._id
    // );

    if (foundMessageIndex !== -1) {
      const updatedMessages = [...messages];
      updatedMessages[foundMessageIndex] = data;
      // updatedMessages[foundMessageIndex] = {
      //   ...updatedMessages[foundMessageIndex],
      //   sent: true,
      // };
      setMessages(updatedMessages);
    } else {
      setMessages([...messages, data]);
    }
  };

  function getRandomId() {
    return Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(2, 10);
  }

  const sendMessage = async (message, type) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };
      setNewMessage("");

      const mentionRegex = /@\[([^\]]+)\]\(\w+\)/;

      const resultString = message.replace(
        mentionRegex,
        (match, mention) => `@${mention}`
      );

      const updatedChats = [...chats];

      const index = updatedChats.findIndex(
        (chat) => chat._id === selectedChat._id
      );

      const currentDate = new Date();
      const formattedDate = currentDate.toISOString();

      const randomId = getRandomId();
      const messageObject = {
        parentMessage: selectedMessage,
        messageType: type,
        sender: user,
        content: resultString,
        chat: chats[index],
        createdAt: formattedDate,
        sent: false,
        randomId: randomId,
      };
      setMessages([...messages, messageObject]);

      setSelectedMessage(null);

      const { data } = await axios.post(
        "/api/message",
        {
          content: resultString,
          parentMessage: selectedMessage,
          messageType: type,
          chatId: selectedChat._id,
          randomId: randomId,
        },
        config
      );

      updateMessage(data);

      if (index !== -1) {
        updatedChats[index].latestMessage = data;
        setChats(updatedChats);
      } else {
        console.log("Chat not found.");
      }

      socket.emit("new message", data);
    } catch (error) {
      toast({
        title: "Error Occurred",
        description: "Unable To send message",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const handleSubmit = async (e) => {
    if ((e.altKey && e.key === "Enter") || (e.shiftKey && e.key === "Enter")) {
      e.preventDefault();
      if (newMessage?.length > 0) {
        setNewMessage((message) => message + "\n");
      }
      return;
    }
    if (e.key === "Enter") {
      e.preventDefault();
    }
    if (e.key === "Enter" && newMessage && newMessage !== "") {
      e.preventDefault();
      socket.emit("stop typing", selectedChat._id);
      sendMessage(newMessage, "text");
      setNewMessage("");
      setSelectedMessage(null);
      // if (textareaRef.current) {
      //   textareaRef.current.setSelectionRange(0, 0);
      // }
    }
  };

  const typingHandler = (e) => {
    setNewMessage(e.target.value);

    if (!socketConnected) return;

    if (!typing) {
      setTyping(true);
      socket.emit("typing", selectedChat._id);
    }
    let lastTypingTime = new Date().getTime();
    var timerLength = 3000;
    setTimeout(() => {
      var timeNow = new Date().getTime();
      var timeDiffrence = timeNow - lastTypingTime;
      if (timeDiffrence >= timerLength && typing) {
        socket.emit("stop typing", selectedChat._id);
        setTyping(false);
      }
    }, timerLength);
  };

  //recording code

  const startRecording = async () => {
    toast({
      title: "Recording Started",
      description: "Start Speaking..",
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "bottom",
    });
    setIsRecording(true);
    try {
      await recorder.initAudio();
      await recorder.initWorker();
      recorder.startRecording();
    } catch (err) {
      console.error(err);
    }
  };
  const stopRecording = async () => {
    setIsRecording(false);
    setLoading(true);
    toast({
      // title: "File Error",
      title: "Recording Stopped",
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "bottom",
    });
    try {
      const blob = await recorder.stopRecording();

      const timestamp = new Date().toISOString().replace(/[^a-zA-Z0-9]/g, "");
      const randomString = Math.random().toString(36).substring(2, 8);
      const uniqueFileName = `${timestamp}_${randomString}_${"recorded.mp3"}`;

      const params = {
        ACL: "public-read",
        Body: blob,
        Bucket: S3_BUCKET,
        Key: "chat_files/" + selectedChat._id + "/" + uniqueFileName,
      };

      bucket.upload(params, (err, data) => {
        if (err) {
          console.error("Error uploading file:", err);
        } else {
          sendMessage(data.Location, "audio");
          setLoading(false);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleEmojiSelect = (e) => {
    const sym = e.unified.split("_");
    const codeArray = [];
    sym.forEach((el) => codeArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codeArray);
    if (newMessage) {
      setNewMessage(newMessage + emoji);
    } else {
      setNewMessage(emoji);
    }
  };

  const getStatus = (user) => {
    if (!selectedChat.isGroupChat) {
      const sender = getSenderFull(user, selectedChat.users);
      const isActive = activeUsers.find(
        (activeUser) => activeUser._id === sender._id
      );
      return isActive;
    }
  };

  return (
    <>
      {selectedChat ? (
        <>
          <Text
            fontSize={{ base: "28px", md: "30px" }}
            w={"100%"}
            fontFamily={"Signika"}
            display={"flex"}
            justifyContent={{ base: "space-between" }}
            alignItems={"center"}
            color={"white"}
          >
            <IconButton
              display={{ base: "flex", md: "none" }}
              icon={<ArrowBackIcon />}
              onClick={() => setSelectedChat("")}
            />

            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              width="100%"
              p={3}
              backgroundColor={"#101010"}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={"6"}
              >
                {!selectedChat.isGroupChat && (
                  <Image
                    src={getSenderFull(user, selectedChat.users).pic}
                    alt="profile"
                    height="80px"
                    width="80px"
                    objectFit={"cover"}
                    borderRadius={"50%"}
                  />
                )}
                <Box>
                  {!selectedChat.isGroupChat ? (
                    <Text>{getSender(user, selectedChat.users)}</Text>
                  ) : (
                    <Text>{selectedChat.chatName.toUpperCase()}</Text>
                  )}
                  {!selectedChat.isGroupChat && (
                    <Box display={"flex"} alignItems={"center"}>
                      <Box
                        backgroundColor={
                          getStatus(user) ? "#8dc73d" : "red.600"
                        }
                        height="10px"
                        width="10px"
                        borderRadius="50%"
                        marginRight={"5px"}
                      ></Box>
                      <Text
                        color={getStatus(user) ? "#8dc73d" : "red.600"}
                        fontSize={"16px"}
                      >
                        {getStatus(user) ? "Active Now" : "Away"}
                      </Text>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box display={"flex"} alignItems={"center"}>
                {/* <Button
                  backgroundColor={"transparent"}
                  _hover={{ backgroundColor: "transparent" }}
                >
                  <PhoneIcon color="#92ce40" fontSize={"25px"} />
                </Button>
                <Button
                  backgroundColor={"transparent"}
                  _hover={{ backgroundColor: "transparent" }}
                >
                  <BsCameraVideoFill color="#92ce40" size={"25px"} />
                </Button> */}
                {!selectedChat.isGroupChat ? (
                  <ChatProfileModal
                    messages={messages}
                    userDetails={getSenderFull(user, selectedChat.users)}
                  />
                ) : (
                  <>
                    <UpdateGroupChatModal
                      messages={messages}
                      fetchAgain={fetchAgain}
                      setFetchAgain={setFetchAgain}
                      fetchmessages={fetchmessages}
                    />
                  </>
                )}
              </Box>
            </Box>
          </Text>
          <Image
            src={Divider}
            marginTop={"10px"}
            height={"3px"}
            alt="Diver"
            objectFit={"contain"}
          />
          <Box
            display={"flex"}
            flexDir={"column"}
            justifyContent={"flex-end"}
            p={3}
            bg={"#101010"}
            w={"100%"}
            h={"100%"}
            overflowY={"hidden"}
            fontFamily={"Signika"}
            position={"relative"}
          >
            {loading ? (
              <Spinner
                size={"xl"}
                w={20}
                h={20}
                alignSelf={"center"}
                margin={"auto"}
                color="#92ce40"
              />
            ) : (
              <div className="messages">
                <ScrollableChat
                  messages={messages}
                  setSelectedMessage={setSelectedMessage}
                />
              </div>
            )}

            <FormControl onKeyDown={handleSubmit} isRequired mt={3}>
              {isTyping ? (
                <div>
                  <Lottie
                    options={defaultOptions}
                    width={70}
                    style={{ marginBottom: 15, marginLeft: 0 }}
                  />
                </div>
              ) : (
                <></>
              )}

              {selectedMessage && (
                <div className="w-full px-[4rem] py-2 bg-gray-800 rounded-t-lg text-white">
                  <div className="w-full bg-black rounded-lg p-2 flex justify-between">
                    <div>
                      <p className="text-xs">
                        {selectedMessage.sender._id === user._id
                          ? "You"
                          : selectedMessage.sender.name}
                      </p>
                      <p>{selectedMessage.content}</p>
                    </div>
                    <button
                      onClick={() => {
                        setSelectedMessage(null);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18 18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              )}
              <div className="flex lg:px-[20px] lg:py-[10px] bg-black rounded-[15px] ">
                <Menu placement="top">
                  <MenuButton
                    backgroundColor={"#000000"}
                    _hover={{ backgroundColor: "#000000" }}
                    as={Button}
                  >
                    <Image
                      src={EmojiIcon}
                      id="emoji-button1"
                      objectFit={"contain"}
                      className="h-[1rem] lg:h-[2rem]"
                    />
                  </MenuButton>
                  <MenuList backgroundColor={"transparent"}>
                    <Picker data={data} onEmojiSelect={handleEmojiSelect} />
                  </MenuList>
                </Menu>
                <MentionsInput
                  placeholder="Use '@' for mention, Double click on message for reply"
                  value={newMessage}
                  onChange={typingHandler}
                  className="flex-1"
                  ref={textareaRef}
                  onPaste={handlePaste}
                  id="message-input"
                  a11ySuggestionsListLabel={"Suggested mentions"}
                >
                  <Mention
                    ref={textareaRef}
                    style={mentionsInputStyle}
                    data={users}
                  />
                </MentionsInput>

                {/* <textarea
                  onChange={typingHandler}
                  value={newMessage}
                  rows={1}
                  className="flex-[0.7] lg:flex-1 bg-transparent text-white focus:outline-none ring-0 px-[0.5rem]"
                  onPaste={handlePaste}
                  style={{ resize: "none" }}
                  ref={textareaRef}
                ></textarea> */}
                <Box>
                  <Menu placement="top">
                    <MenuButton
                      backgroundColor={"#000000"}
                      _hover={{ backgroundColor: "#000000" }}
                      as={Button}
                      padding={"0px"}
                    >
                      <Image
                        src={AttachmentIcon}
                        className="h-[1rem] lg:h-[2rem] object-contain"
                      />
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={openDocumentInput}>Doc</MenuItem>
                      <MenuItem onClick={openImageInput}>Image</MenuItem>
                      <MenuItem onClick={openVideoInput}>Video</MenuItem>
                      <MenuItem onClick={openAudioInput}>Audio</MenuItem>
                    </MenuList>
                  </Menu>
                </Box>
                {!isRecording && (
                  <Button
                    backgroundColor={"#000000"}
                    _hover={{ backgroundColor: "#000000" }}
                    onClick={startRecording}
                    padding={"0px"}
                    className="ml-[-20px] lg:ml-0"
                  >
                    <Image
                      src={MicIcon}
                      className="h-[1rem] lg:h-[2rem] object-contain"
                    />
                  </Button>
                )}
                {isRecording && (
                  <Button
                    backgroundColor={"#000000"}
                    _hover={{ backgroundColor: "#000000" }}
                    onClick={stopRecording}
                    padding={"0px"}
                    className="!ml-[-20px]"
                  >
                    <BsStopBtnFill size={"24px"} color="#808080" />
                  </Button>
                )}
                <button
                  backgroundColor={"transparent"}
                  _hover={{ backgroundColor: "transparent" }}
                  onClick={() => {
                    sendMessage(newMessage, "text");
                  }}
                >
                  <Image
                    src={SendIcon}
                    className="h-[1rem] lg:h-[2rem] object-contain"
                  />
                </button>
              </div>

              <VisuallyHiddenInput
                type="file"
                p={1.5}
                accept="*/*"
                id="document"
                ref={docInputRef}
                onChange={(e) => {
                  upload(e.target.files[0], "document");
                }}
              />
              <VisuallyHiddenInput
                type="file"
                p={1.5}
                accept="image/jpeg,image/png,image/jpg"
                id="image"
                ref={imageInputRef}
                onChange={(e) => {
                  upload(e.target.files[0], "image");
                }}
              />
              <VisuallyHiddenInput
                type="file"
                p={1.5}
                accept="video/mp4,video/mov"
                id="video"
                ref={videoInputRef}
                onChange={(e) => {
                  upload(e.target.files[0], "video");
                }}
              />
              <VisuallyHiddenInput
                type="file"
                p={1.5}
                accept="audio/mp3"
                id="audio"
                ref={audioInputRef}
                onChange={(e) => {
                  upload(e.target.files[0], "audio");
                }}
              />
            </FormControl>
          </Box>
        </>
      ) : (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          h={"100%"}
        >
          <Text fontSize={"31px"} pb={3} fontFamily={"Signika"} color={"white"}>
            Click on user to start Chatting
          </Text>
        </Box>
      )}
    </>
  );
};

export default SingleChat;
