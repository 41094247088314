import React from "react";
import { isSameSenderMargin, isSameUser } from "../../config/ChatLogics";
import { ChatState } from "../../Context/ChatProvider";
import { Text } from "@chakra-ui/react";
import formatDate from "../../hooks/formatDate";

function TextMessage({ messages, message, i, setSelectedMessage }) {
  const { user } = ChatState();

  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = message.content.split(urlRegex);
  return (
    <div
      onDoubleClick={() => {
        setSelectedMessage(message);
        document.getElementById("message-input").focus();
      }}
      style={{
        marginLeft: isSameSenderMargin(messages, message, i, user._id),
        marginTop: isSameUser(messages, message, i, user._id) ? 3 : 10,
      }}
      className={`w-full flex ${
        message.sender._id === user._id ? "flex-row-reverse" : ""
      }`}
    >
      <div
        style={{
          backgroundColor:
            message.sender._id === user._id ? "#2f2f2f" : "#1d1d1d",
          borderRadius: "20px",
          padding: "5px 15px",
          maxWidth: "75%",
        }}
      >
        <div className="flex justify-end text-xs text-white py-1">
          {message.sender._id !== user._id && <p>{message.sender.name}</p>}
        </div>
        {message.parentMessage && (
          <div>
            <div
              className={`text-white rounded-lg p-2 ${
                message.sender._id === user._id
                  ? "bg-[#1d1d1d]"
                  : "bg-[#2f2f2f]"
              }`}
            >
              <p className="text-xs">
                {message.parentMessage.sender._id === user._id
                  ? "You"
                  : message.parentMessage.sender.name}
              </p>
              <p className="text-sm">{message.parentMessage.content}</p>
            </div>
          </div>
        )}
        <Text
          color={message.sender._id === user._id ? "#ffffff" : "green.400"}
          className="display-linebreak"
        >
          {parts.map((part, index) =>
            urlRegex.test(part) ? (
              <a
                key={index}
                href={part}
                target="_blank"
                className="underline"
                rel="noopener noreferrer"
              >
                {part}
              </a>
            ) : (
              <span key={index}>{part}</span>
            )
          )}
        </Text>
        <div
          className={`flex ${
            message.sender._id === user._id ? "justify-start" : "justify-end"
          } text-xs text-white/50`}
        >
          <div className="flex items-center gap-4">
            <p>{formatDate(message.createdAt)}</p>
            {message.sent === true && <p>Sent</p>}
            {message.sent === false && <p>Sending</p>}
            {message.sent === undefined ||
            message.sent === null ||
            message.sent === ""
              ? null
              : ""}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TextMessage;
