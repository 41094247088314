import { Avatar, Box, Text } from "@chakra-ui/react";
import React from "react";

const UserListItem = ({ user, handleFunction }) => {
  return (
    <Box
      onClick={handleFunction}
      cursor={"pointer"}
      background="#0e0e0e"
      fontFamily={"Signika"}
      _hover={{
        background: "#92ce40",
        color: "white",
      }}
      width={"100%"}
      display={"flex"}
      alignItems={"center"}
      color={"white"}
      px={3}
      py={2}
      mb={3}
      borderRadius={"lg"}
    >
      <Avatar
        mr={2}
        size={"sm"}
        cursor={"pointer"}
        name={user.name}
        src={user.pic}
      />
      <Box>
        <Text>{user.name}</Text>
        <Text fontSize="xs">Email: {user.email}</Text>
      </Box>
    </Box>
  );
};

export default UserListItem;
