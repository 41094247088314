import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Image,
  Input,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useState, useRef } from "react";
import { AiOutlineWechat } from "react-icons/ai";
import { BiSolidUserCheck } from "react-icons/bi";
import { IoSettings } from "react-icons/io5";
import { RiLogoutBoxFill } from "react-icons/ri";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ChatState } from "../../Context/ChatProvider";
import ActiveUsers from "../ActiveUsers";
import ChatLoading from "../ChatLoading";
import MeetingList from "../Meeting/List";
import UserListItem from "../UserAvatar/UserListItem";
import { BellIcon, CalendarIcon } from "@chakra-ui/icons";
import socket from "./socket";
import ProfileModal from "./ProfileModal";
import { SlLogout } from "react-icons/sl";
import { Effect } from "react-notification-badge";
import NotificationBadge from "react-notification-badge/lib/components/NotificationBadge";
import NotificationModal from "../NotificaitonModal";
import { AiFillNotification } from "react-icons/ai";
import BroadCastModal from "../BroadCastModal";
import Logo from "../../assets/img/Logo.png";
import ActiveChatIcon from "../../assets/icons/Chat_Icon.png";
import InActiveChatIcon from "../../assets/icons/Chat_Icon_Green.png";
import LogoutIcon from "../../assets/icons/LogOut.png";
import ActiveUserIcon from "../../assets/icons/ActiveUsers.png";
import MeetingIcon from "../../assets/icons/ScheduleMeets.png";
import NotificationIcon from "../../assets/icons/Notifications.png";
import BroadCastIcon from "../../assets/icons/Broadcast.png";
import SettingsIcon from "../../assets/icons/Settings.png";

const SideDrawer = () => {
  const [search, setsearch] = useState("");
  const [seachResult, setseachResult] = useState([]);
  const [loading, setloading] = useState(false);
  const [loadingChat, setloadingChat] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isActiveUsersOpen, setIsActiveUsersOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isMeetingModalOpen, setIsMeetingModalOpen] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [isBroadCastModalOpen, setIsBroadCastModalOpen] = useState(false);

  const outerDivRef = useRef(null);

  const [activePanel, setActivePanel] = useState("chat");

  const {
    user,
    setSelectedChat,
    chats,
    setChats,
    notification,
    setNotification,
    showSidebar,
    setShowSidebar,
  } = ChatState();

  const history = useHistory();

  const logoutHandler = () => {
    console.log("asdf");
    socket.disconnect({ reason: "test" });
    localStorage.removeItem("userInfo");
    history.push("/");
  };
  const toast = useToast();

  const accessChat = async (userId) => {
    try {
      setloadingChat(true);
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };
      const { data } = await axios.post("/api/chat", { userId }, config);
      if (!chats.find((c) => c._id === data._id)) setChats([data, ...chats]);

      setSelectedChat(data);
      setloadingChat(false);
      onClose();
    } catch (error) {
      toast({
        title: "Failed fectching Chat",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };

  const handleSearch = async () => {
    if (!search) {
      toast({
        title: "Please Enter something in search",

        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top-left",
      });
    }

    try {
      setloading(true);
      const config = {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      };

      const { data } = await axios.get("/api/user?search=" + search, config);
      setloading(false);
      setseachResult(data);
    } catch (error) {
      toast({
        title: "Failed to load search result",

        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };

  const handleOuterDivClick = (e) => {
    if (
      outerDivRef.current &&
      !outerDivRef.current.classList.contains("-translate-x-full")
    ) {
      setShowSidebar(false);
    }
  };

  return (
    <div
      // style={{ background: "#1d1d1d" }}
      ref={outerDivRef}
      onClick={handleOuterDivClick}
      className={`absolute lg:static z-[9999] lg:z-auto w-screen lg:w-auto bg-transparent  lg:translate-x-0 transition-all duration-300 ${
        !showSidebar ? "-translate-x-full" : ""
      }`}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        alignItems={"center"}
        bg={"#101010"}
        fontFamily={"Signika"}
        height={"99vh"}
        borderWidth={"0px"}
        color={"white"}
        className=" z-[9999] bg-red-600 lg:static w-[90px]"
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          className="gap-4 2xl:gap-9"
        >
          <Image src={Logo} className="h-[8rem] mx-[10px] object-contain" />
          <Button
            marginTop={"10px"}
            backgroundColor={"transparent"}
            _hover={{ backgroundColor: "transparent" }}
            onClick={() => {
              setActivePanel("chat");
            }}
          >
            <Image src={ActiveChatIcon} className="w-[10rem] object-contain" />
          </Button>
          <Button
            onClick={() => {
              setIsActiveUsersOpen(true);
              setActivePanel("activeUsers");
            }}
            marginTop={"10px"}
            backgroundColor={"transparent"}
            _hover={{ backgroundColor: "transparent" }}
          >
            <Image src={ActiveUserIcon} className="w-[3rem] object-contain" />
            <ActiveUsers
              isOpen={isActiveUsersOpen}
              closeModal={() => {
                setIsActiveUsersOpen(false);
              }}
            />
          </Button>
          <Button
            onClick={() => {
              setIsMeetingModalOpen(true);
              setActivePanel("meeting");
            }}
            marginTop={"10px"}
            backgroundColor={"transparent"}
            _hover={{ backgroundColor: "transparent" }}
          >
            <Image src={MeetingIcon} className="w-[3rem] object-contain" />
            <MeetingList
              isOpen={isMeetingModalOpen}
              closeModal={() => {
                setIsMeetingModalOpen(false);
              }}
            />
          </Button>
          <Button
            onClick={() => {
              setIsNotificationModalOpen(true);
              setActivePanel("notifications");
            }}
            marginTop={"10px"}
            backgroundColor={"transparent"}
            _hover={{ backgroundColor: "transparent" }}
          >
            {notification.length !== 0 && (
              <NotificationBadge
                count={notification.length}
                effect={Effect.SCALE}
              />
            )}
            <Image src={NotificationIcon} className="w-[3rem] object-contain" />
            <NotificationModal
              isOpen={isNotificationModalOpen}
              closeModal={() => {
                setIsNotificationModalOpen(false);
              }}
            />
          </Button>
          <Button
            onClick={() => {
              setIsBroadCastModalOpen(true);
              setActivePanel("broadcast");
            }}
            marginTop={"10px"}
            backgroundColor={"transparent"}
            _hover={{ backgroundColor: "transparent" }}
          >
            <Image src={BroadCastIcon} className="w-[3rem] object-contain" />
            <BroadCastModal
              isOpen={isBroadCastModalOpen}
              closeModal={() => {
                setIsBroadCastModalOpen(false);
              }}
            />
          </Button>

          <Button
            onClick={() => {
              setIsProfileOpen(true);
              setActivePanel("settings");
            }}
            marginTop={"10px"}
            backgroundColor={"transparent"}
            _hover={{ backgroundColor: "transparent" }}
          >
            <Image src={SettingsIcon} className="w-[3rem] object-contain" />
            <ProfileModal
              isOpen={isProfileOpen}
              closeModal={() => {
                setIsProfileOpen(false);
              }}
            />
          </Button>
        </Box>
        <Box>
          <Button
            onClick={logoutHandler}
            backgroundColor={"transparent"}
            _hover={{ backgroundColor: "transparent" }}
            className="mt-[-60px]"
          >
            {/* <SlLogout size="30px" color="#808080" /> */}
            <Image src={LogoutIcon} className="h-[5rem] object-contain" />
          </Button>
        </Box>
      </Box>

      <Drawer isOpen={isOpen} placement="left" onClose={onClose} bg={"#1d1d1d"}>
        <DrawerOverlay />
        <DrawerContent bg={"#1d1d1d"} color={"white"} borderBottomWidth={"0px"}>
          <DrawerHeader borderBottomWidth={"1px"}>Search Users</DrawerHeader>

          <DrawerBody>
            <Box display={"flex"} pb={2} pt={3}>
              <Input
                placeholder="Search by name or email"
                mr={2}
                value={search}
                onChange={(e) => setsearch(e.target.value)}
                fontFamily={"Signika"}
              ></Input>
              <Button
                onClick={handleSearch}
                bg={"#92ce40"}
                fontFamily={"Signika"}
              >
                Go
              </Button>
            </Box>
            {loading ? (
              <ChatLoading />
            ) : (
              seachResult?.map((user) => (
                <UserListItem
                  key={user._id}
                  user={user}
                  handleFunction={() => accessChat(user._id)}
                />
              ))
            )}
            {loadingChat && <Spinner ml={"auto"} display={"flex"} />}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default SideDrawer;
