import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { ChatState } from "../Context/ChatProvider";
import { getSender } from "../config/ChatLogics";
import { useEffect } from "react";
import axios from "axios";

const NotificationModal = ({ isOpen, closeModal }) => {
  const {
    user,
    notification,
    setSelectedChat,
    setNotification,
    broadCast,
    setBroadCast,
  } = ChatState();

  const toast = useToast();

  const setChat = (chat) => {
    setSelectedChat(chat);
    closeModal();
  };

  const fetchBroadCasts = async () => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      };
      const { data } = await axios.get("/api/broadcast", config);
      setBroadCast(data);
    } catch (error) {
      toast({
        title: "Failed fectching Chat",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };

  useEffect(() => {
    fetchBroadCasts();
  }, []);

  return (
    <>
      <Modal size={"xl"} isOpen={isOpen} onClose={closeModal} isCentered>
        <ModalOverlay />
        <ModalContent
          h={"410px"}
          background={"#1d1d1d"}
          color={"white"}
          borderRadius={"50px"}
          margin={"10px"}
        >
          <ModalCloseButton
            color={"#92ce40"}
            fontWeight={"bold"}
            fontSize={"20px"}
            margin={"20px"}
          />
          <ModalBody
            display="flex"
            flexDir={"column"}
            border={"2px solid #92ce40"}
            borderRadius={"50px"}
            height={"300px"}
            overflow={"auto"}
          >
            <Box>
              <Text
                textAlign={"center"}
                fontWeight={"bold"}
                color={" #92ce40"}
                marginTop={"10px"}
                fontSize={"20px"}
              >
                Notifications
              </Text>
              <Box marginTop={"10px"}>
                {!notification.length && (
                  <Box
                    backgroundColor={"gray"}
                    padding={"4px"}
                    borderRadius={"5px"}
                  >
                    <Text fontSize={"18px"}>No new Messages</Text>
                  </Box>
                )}
                {notification?.map((notif) => (
                  <Box
                    backgroundColor={"#0e0e0e"}
                    padding={"4px"}
                    cursor={"pointer"}
                    borderRadius={"5px"}
                    onClick={() => {
                      setChat(notif.chat);
                      setNotification(notification.filter((n) => n !== notif));
                    }}
                  >
                    <Text fontSize={"18px"}>
                      {notif.chat.isGroupChat
                        ? "New Message in " + notif.chat.chatName
                        : "New Message from " +
                          getSender(user, notif.chat.users)}
                    </Text>
                  </Box>
                ))}
                {broadCast?.map((boradcast) => (
                  <Box
                    backgroundColor={"#0e0e0e"}
                    padding={"4px"}
                    borderRadius={"5px"}
                    marginTop={"2"}
                    paddingY={"4px"}
                  >
                    <Text fontWeight={"bold"} fontSize={"18px"}>
                      {boradcast.title}
                    </Text>
                    <Text fontSize={"18px"}>{boradcast.content}</Text>
                  </Box>
                ))}
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NotificationModal;
