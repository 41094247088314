import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Image,
  Box,
  VisuallyHiddenInput,
  useToast,
  Spinner,
  GridItem,
  Text,
  Grid,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { EditIcon } from "@chakra-ui/icons";
import axios from "axios";
import { ChatState } from "../../Context/ChatProvider";
import CustomEdit from "../CustomEdit";
import CryptoJS from "crypto-js";
import { S3_BUCKET, bucket } from "../../config";

const ProfileModal = ({ isOpen, closeModal }) => {
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const { user, setuser } = ChatState();

  const [userName, setUserName] = useState(user.name);
  const [userMobileNumber, setUserMobileNumber] = useState(user.mobileNumber);
  const [userPassword, setUserPassword] = useState("");

  const imageInputRef = useRef(null);
  const toast = useToast();

  const openFileManager = () => {
    imageInputRef.current.click();
  };

  function isSupportedFileType(file, fileType) {
    const supportedFileTypes = {
      image: ["image/jpeg", "image/png", "image/jpg"],
      audio: ["audio/mp3", "audio/mpeg"],
      video: ["video/mp4", "video/mov"],
      document: ["application/pdf", "text/plain", "application/msword"],
    };

    if (supportedFileTypes[fileType]) {
      return supportedFileTypes[fileType].includes(file.type);
    }
    return false;
  }

  const updateUser = (user) => {
    setuser(user);
    localStorage.setItem("userInfo", JSON.stringify(user));
  };

  const updatePic = async (url) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };
      const { data } = await axios.post(
        "/api/user/update/pic",
        {
          pic: url,
        },
        config
      );
      updateUser(data);
    } catch (error) {
      console.log(error);
      toast({
        title: "Error Occured",
        description: "Unable To Update Profile Image",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const getPublicIdFromUrl = (url) => {
    const regex = /\/([^/]+)\.\w{3,4}$/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const generateSHA1 = (data) => {
    const sha1Hash = CryptoJS.SHA1(data).toString();
    return sha1Hash;
  };

  // const generateSignature = (publicId, apiSecret) => {
  //   const timestamp = new Date().getTime();
  //   return `public_id=${publicId}&timestamp=${timestamp}${apiSecret}`;
  // };

  const deleteProfileImage = async () => {
    const profile_image = user.pic;

    const path = new URL(profile_image).pathname;
    console.log(path);

    const objectKey = path.startsWith("/") ? path.substring(1) : path;

    console.log(objectKey);

    const params = {
      Bucket: S3_BUCKET,
      Key: objectKey,
    };

    bucket.deleteObject(params, (deleteErr, deleteData) => {
      if (deleteErr) {
        console.error("Error deleting file:", deleteErr);
      } else {
        console.log("File deleted successfully:", deleteData);
      }
    });

    // const publicId = getPublicIdFromUrl(profile_image);

    // const cloudName = "doqmmqd4h";
    // const timestamp = new Date().getTime();
    // const apiKey = "877791163248849";
    // const apiSecret = "KVAe1mkMaW8U-SboMESEDVf9pQ0";
    // const signature = generateSHA1(generateSignature(publicId, apiSecret));
    // const url = `https://api.cloudinary.com/v1_1/${cloudName}/image/destroy`;

    // try {
    //   const response = await axios.post(url, {
    //     public_id: publicId,
    //     signature: signature,
    //     api_key: apiKey,
    //     timestamp: timestamp,
    //   });
    //   console.error(response);
    // } catch (error) {
    //   console.error(error);
    // }
  };

  const upload = async (file) => {
    setLoading(true);
    if (isSupportedFileType(file, "image")) {
      deleteProfileImage();
      // return;

      console.log("uploding file");

      const timestamp = new Date().toISOString().replace(/[^a-zA-Z0-9]/g, "");
      const randomString = Math.random().toString(36).substring(2, 8);
      const uniqueFileName = `${timestamp}_${randomString}_${file.name}`;

      const params = {
        ACL: "public-read",
        Body: file,
        Bucket: S3_BUCKET,
        Key: "profile_images/" + uniqueFileName,
      };

      bucket.upload(params, (err, data) => {
        if (err) {
          console.error("Error uploading file:", err);
        } else {
          console.log("File uploaded successfully:", data);
          console.log(data.Location);
          updatePic(data.Location);
        }
        setLoading(false);
      });

      // const data = new FormData();
      // data.append("file", file);
      // data.append("upload_preset", "meramind");
      // data.append("cloud_name", "doqmmqd4h");
      // fetch("https://api.cloudinary.com/v1_1/doqmmqd4h/image/upload", {
      //   method: "post",
      //   body: data,
      // })
      //   .then((res) => res.json())
      //   .then((data) => {
      //     const url = data.url.toString();
      //     updatePic(url);
      //     setLoading(false);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     setLoading(false);
      //   });
    } else {
      toast({
        title: "Image Error",
        description: "Please select an image",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setLoading(false);
    }
  };

  const updateMobileNumber = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };
      const { data } = await axios.post(
        "/api/user/update/number",
        {
          mobileNumber: userMobileNumber,
        },
        config
      );
      updateUser(data);
    } catch (error) {
      console.log(error);
      toast({
        title: "Error Occured",
        description: "Unable To Update Mobile Number",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const updateName = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };
      const { data } = await axios.post(
        "/api/user/update/name",
        {
          name: userName,
        },
        config
      );
      updateUser(data);
    } catch (error) {
      console.log(error);
      toast({
        title: "Error Occured",
        description: "Unable To Update Name",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const updatePassword = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };
      const { data } = await axios.post(
        "/api/user/update/password",
        {
          password: userPassword,
        },
        config
      );
      setUserPassword("");
      updateUser(data);
    } catch (error) {
      console.log(error);
      toast({
        title: "Error Occured",
        description: "Unable To Update Password",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <>
      <VisuallyHiddenInput
        type="file"
        p={1.5}
        accept="image/jpeg,image/png,image/jpg"
        id="image"
        ref={imageInputRef}
        onChange={(e) => {
          upload(e.target.files[0]);
        }}
      />
      <Modal size={"xl"} isOpen={isOpen} onClose={closeModal} isCentered>
        <ModalOverlay />
        <ModalContent
          h={"410px"}
          background={"#000000"}
          color={"white"}
          borderRadius={"50px"}
          margin={"10px"}
        >
          <ModalCloseButton
            color={"#92ce40"}
            fontWeight={"bold"}
            fontSize={"20px"}
            margin={"20px"}
          />
          <ModalBody
            alignItems={"center"}
            display="flex"
            flexDir={"column"}
            border={"2px solid #92ce40"}
            borderRadius={"50px"}
          >
            <Box position={"relative"}>
              <Image
                src={user.pic}
                borderRadius={"full"}
                boxSize={"150px"}
                alt={user.name}
                marginTop={"-70px"}
                border={"4px solid #92ce40"}
              />
              {loading && <Spinner size={"lg"} />}
            </Box>

            <Button
              onClick={openFileManager}
              backgroundColor={"transparent"}
              _hover={{ backgroundColor: "transparent" }}
              color={"#ffffff"}
              textDecoration={"underline"}
            >
              Edit Profile Picture
            </Button>

            <CustomEdit
              title="Name"
              value={userName}
              setValue={setUserName}
              updateValue={updateName}
            />
            <Grid templateColumns="repeat(2, 1fr)" gap={2}>
              <GridItem
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
              >
                <Text fontSize={"xl"} fontWeight={"bold"}>
                  Employee Id:
                </Text>
              </GridItem>
              <GridItem>
                <Box display={"flex"} alignItems={"center"} gap={"2"}>
                  <Text fontSize={"20px"} color={"#92ce40"} fontWeight={"bold"}>
                    {user.employeeId}
                  </Text>
                </Box>
              </GridItem>
            </Grid>
            <Grid templateColumns="repeat(2, 1fr)" gap={2}>
              <GridItem
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
              >
                <Text fontSize={"xl"} fontWeight={"bold"}>
                  Email Id:
                </Text>
              </GridItem>
              <GridItem>
                <Box display={"flex"} alignItems={"center"} gap={"2"}>
                  <Text fontSize={"20px"} color={"#92ce40"} fontWeight={"bold"}>
                    {user.email}
                  </Text>
                </Box>
              </GridItem>
            </Grid>
            <CustomEdit
              title="Mobile Number"
              value={userMobileNumber}
              setValue={setUserMobileNumber}
              updateValue={updateMobileNumber}
            />
            <CustomEdit
              title="Password"
              value={userPassword}
              setValue={setUserPassword}
              updateValue={updatePassword}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {/* <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                <GridItem
                  display={"flex"}
                  justifyContent={"end"}
                  alignItems={"center"}
                >
                  <Text fontSize={"xl"} fontWeight={"bold"}>
                    Status
                  </Text>
                </GridItem>
                <GridItem>
                  <Box display={'flex'} alignItems={'center'} gap={'2'}>
                    <Box
                      height={"10px"}
                      width={"10px"}
                      borderRadius={"50%"}
                      backgroundColor={"#92ce40"}
                    ></Box>
                    <Text fontSize={'20px'} color={"#92ce40"} fontWeight={"bold"}>
                      Online
                    </Text>
                  </Box>
                </GridItem>
              </Grid> */}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProfileModal;
