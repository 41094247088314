import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  FormControl,
  Input,
  useToast,
  Spinner,
  Text,
} from "@chakra-ui/react";

function ImageMedia({ m }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <div>
      <img
        onClick={onOpen}
        src={m.content}
        height="100px"
        width="100%"
        style={{ objectFit: "container" }}
        alt={m.content}
      />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        background={"#282828"}
        color={"white"}
      >
        <ModalOverlay />
        <ModalContent background={"#282828"} color={"white"}>
          <ModalCloseButton />
          <ModalBody>
            <img src={m.content} alt="" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default ImageMedia;
