import React from "react";
import ScrollableFeed from "react-scrollable-feed";
import {
  isLastMessage,
  isSameSender,
  isSameSenderMargin,
  isSameUser,
} from "../config/ChatLogics";
import { ChatState } from "../Context/ChatProvider";
import { Avatar, Tooltip } from "@chakra-ui/react";
import TextMessage from "./Message/TextMessage";
import DocumentMessage from "./Message/DocumentMessage";
import ImageMessage from "./Message/ImageMessage";
import AudioMessage from "./Message/AudioMessage";
import VideoMessage from "./Message/VideoMessage";

const ScrollableChat = ({ messages, setSelectedMessage }) => {
  const { user } = ChatState();
  return (
    <ScrollableFeed>
      {messages &&
        messages.map((m, i) => (
          <div style={{ display: "flex" }} key={m._id}>
            {(isSameSender(messages, m, i, user._id) ||
              isLastMessage(messages, i, user._id)) && (
              <Tooltip label={m.sender.name} placement="bottom-start" hasArrow>
                <Avatar
                  mt={"7px"}
                  mr={1}
                  size={"sm"}
                  cursor={"pointer"}
                  name={m.sender.name}
                  src={m.sender.pic}
                />
              </Tooltip>
            )}

            {m.messageType === "text" && (
              <TextMessage
                messages={messages}
                message={m}
                i={i}
                setSelectedMessage={setSelectedMessage}
              />
            )}
            {m.messageType === "document" && (
              <DocumentMessage messages={messages} message={m} i={i} />
            )}
            {m.messageType === "image" && (
              <ImageMessage messages={messages} message={m} i={i} />
            )}
            {m.messageType === "video" && (
              <VideoMessage messages={messages} message={m} i={i} />
            )}
            {m.messageType === "audio" && (
              <AudioMessage messages={messages} message={m} i={i} />
            )}
          </div>
        ))}
    </ScrollableFeed>
  );
};

export default ScrollableChat;
