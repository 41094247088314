import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  Text,
  useDisclosure,
  Avatar,
  useToast,
  Input,
} from "@chakra-ui/react";
import { ChatState } from "../Context/ChatProvider";
import UserListItem from "./UserAvatar/UserListItem";
import axios from "axios";
import { ImSearch } from "react-icons/im";

function ActiveUsers({ isOpen, closeModal }) {
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const { activeUsers } = ChatState();

  const [loadingChat, setloadingChat] = useState();

  const { user, setSelectedChat, chats, setChats } = ChatState();

  const toast = useToast();

  const [activeUsersList, setActiveUsersList] = useState([]);

  const accessChat = async (userId) => {
    try {
      setloadingChat(true);
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };
      const { data } = await axios.post("/api/chat", { userId }, config);
      if (!chats.find((c) => c._id === data._id)) setChats([data, ...chats]);

      setSelectedChat(data);
      setloadingChat(false);
      closeModal();
    } catch (error) {
      toast({
        title: "Failed fectching Chat",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };
  useEffect(() => {
    setActiveUsersList(activeUsers);
  }, [activeUsers]);

  const search = (e) => {
    if (e.target.value) {
      const searchResults = activeUsersList.filter((user) =>
        user.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setActiveUsersList(searchResults);
    } else {
      setActiveUsersList(activeUsers);
    }
  };

  const ActiveUserItem = ({ user, handleFunction }) => {
    return (
      <Box
        onClick={handleFunction}
        cursor={"pointer"}
        fontFamily={"Signika"}
        _hover={{
          background: "#92ce40",
          color: "white",
        }}
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        color={"white"}
        px={3}
        py={2}
        mb={3}
        borderRadius={"lg"}
      >
        <Avatar
          mr={2}
          size={"sm"}
          cursor={"pointer"}
          name={user.name}
          src={user.pic}
        />
        <Box>
          <Text fontSize={"28px"}>{user.name}</Text>
        </Box>
      </Box>
    );
  };

  return (
    <div>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={closeModal}
        bg={"#1d1d1d"}
      >
        <DrawerOverlay />
        <DrawerContent bg={"#1d1d1d"} color={"white"} borderBottomWidth={"0px"}>
          <DrawerHeader
            borderBottomWidth={"1px"}
            fontSize={"28px"}
            fontWeight={"bold"}
            color={"#92ce40"}
          >
            Active Users
          </DrawerHeader>
          <Box padding={"5px"} marginTop={"10px"}>
            <Box
              display={"flex"}
              alignItems={"center"}
              backgroundColor={"#000000"}
              padding={"5px 15px"}
              borderRadius={"15px"}
            >
              <ImSearch color={"#808080"} />
              <Input
                className="no-outline"
                placeholder="Search"
                onChange={search}
                border={"0"}
                focusBorderColor="transparent" 
              />
            </Box>
          </Box>

          <DrawerBody>
            <Box pb={2} pt={3}>
              {activeUsersList
                ?.filter((u) => u._id !== user._id)
                .map((u) => (
                  <ActiveUserItem
                    key={u._id}
                    user={u}
                    handleFunction={() => accessChat(u._id)}
                  />
                ))}
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
}

export default ActiveUsers;
