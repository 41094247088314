import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Text,
  FormControl,
  Input,
  useToast,
  Spinner,
  Button,
  Grid,
  GridItem,
  Icon,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { ChatState } from "../../Context/ChatProvider";
import { AiFillSchedule } from "react-icons/ai";
import { GrSchedulePlay } from "react-icons/gr";
import UserBadgeItem from "../UserAvatar/UserBadgeItem";
import UserListItem from "../UserAvatar/UserListItem";

import ScheduleMeetingActiveIcon from "../../assets/icons/Schedule_A_Meets.png";
import ScheduleMeetingInActiveIcon from "../../assets/icons/Schedule_A_Meets_White.png";

import ScheduledMeetingsActiveIcon from "../../assets/icons/ScheduledMeetingsGreen.png";
import ScheduledMeetingsInActiveIcon from "../../assets/icons/ScheduledMeetingsWhite.png";

function MeetingList({ isOpen, closeModal }) {
  const { user, meetings, setMeetings } = ChatState();
  const [activeTab, setActiveTab] = useState(0);

  const [meetingDate, setMeetingDate] = useState();
  const [meetingTime, setMeetingTime] = useState();
  const [meetingName, setMeetingName] = useState();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [loading, setLoading] = useState(false);

  const toast = useToast();

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const handleSearch = async (query) => {
    setSearch(query);
    if (!query) {
      // setSearchResult([]);
      return;
    }
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      };
      const { data } = await axios.get("/api/user?search=" + search, config);

      setLoading(false);
      setSearchResult(data);
    } catch (error) {
      toast({
        title: "Error Occured",
        description: "Fail to load search result",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };

  const handleSubmit = async () => {
    if (!meetingName || !selectedUsers || !meetingDate || !meetingTime) {
      toast({
        title: "Please fill all the details",

        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      };
      const { data } = await axios.post(
        "/api/meeting/create",
        {
          name: meetingName,
          users: JSON.stringify(selectedUsers.map((u) => u._id)),
          meetingDate: meetingDate,
          meetingTime: meetingTime,
        },
        config
      );

      setMeetings([data, ...meetings]);
      closeModal();
      setLoading(false);
      // setSearchResult([]);
      toast({
        title: "Meeting has been Created",

        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    } catch (error) {
      toast({
        title: "Error Occured",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };

  const getMeetings = async () => {
    const config = {
      headers: {
        Authorization: "Bearer " + user.token,
      },
    };
    const { data } = await axios.get("/api/meeting", config);
    console.log(data);
    setMeetings(data);
  };

  const formatDateTime = (meetingDate, meetingTime) => {
    if (meetingDate && meetingTime) {
      const dateTimeString = `${meetingDate}T${meetingTime}`;
      const dateTime = new Date(dateTimeString);
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];

      const day = dateTime.getDate();
      const month = months[dateTime.getMonth()];
      const year = dateTime.getFullYear();
      const hours = dateTime.getHours();
      const minutes = dateTime.getMinutes();
      const ampm = hours >= 12 ? "pm" : "am";

      const formattedDate = `${day} ${month} ${year} ${hours % 12}:${String(
        minutes
      ).padStart(2, "0")} ${ampm}`;
      return formattedDate;
    }
  };

  const getUsers = (users) => {
    const maxUsersToShow = 2;
    const userList = users.map((user, index) => {
      return user.name;
    });

    if (userList.length > maxUsersToShow) {
      const remainingUsersCount = userList.length - maxUsersToShow;
      return (
        userList.slice(0, maxUsersToShow).join(", ") +
        ` + ${remainingUsersCount} others`
      );
    }

    return userList.join(", ");
  };
  useEffect(() => {
    getMeetings();
  }, []);

  const handleDelete = (userTodelete) => {
    setSelectedUsers(
      selectedUsers.filter((sel) => sel._id !== userTodelete._id)
    );
  };

  const handleMeeting = (userToadd) => {
    if (selectedUsers.includes(userToadd)) {
      toast({
        title: "User already Added",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    setSelectedUsers([...selectedUsers, userToadd]);
    // setSearchResult([]);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        background={"#000000"}
        color={"white"}
        className="px-5"
      >
        <ModalOverlay />
        <ModalContent
          maxW="850px"
          background={"#000000"}
          color={"white"}
          border={"2px solid #92ce40"}
          borderRadius={"50px"}
          className="w-[80%] lg:w-[850px] lg:px-[20px] lg:py-[40px]"
        >
          <ModalCloseButton
            fontSize={"20px"}
            color={"#92ce40"}
            margin={"20px"}
          />
          <ModalBody marginTop={"20px"}>
            <Tabs
              isLazy
              index={activeTab}
              onChange={handleTabChange}
              isFitted
              colorScheme="whatsapp"
              variant={"unstyled"}
            >
              <TabList>
                <Tab className="flex flex-col">
                  <img
                    src={
                      activeTab === 0
                        ? ScheduledMeetingsActiveIcon
                        : ScheduledMeetingsInActiveIcon
                    }
                    alt="icon"
                    className="h-[3rem] lg:h-auto"
                  />

                  <Text
                    className={` text-[16px] lg:text-[20px] ${
                      activeTab === 0 ? "text-[#92ce40]" : "text-white"
                    }`}
                  >
                    Scheduled Meetings
                  </Text>
                </Tab>
                <Tab className="flex flex-col">
                  <img
                    src={
                      activeTab === 1
                        ? ScheduleMeetingActiveIcon
                        : ScheduleMeetingInActiveIcon
                    }
                    alt="icon"
                    className="h-[3rem] lg:h-auto"
                  />
                  <Text
                    className={`text-[16px] lg:text-[20px] ${
                      activeTab === 1 ? "text-[#92ce40]" : "text-white"
                    }`}
                  >
                    Schedule Meeting
                  </Text>
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  {meetings && (
                    <TableContainer>
                      <Table variant="unstyled" maxWidth={"100%"}>
                        <Thead>
                          <tr className="flex gap-4 w-full justify-between">
                            <td className="text-[16px] lg:text-[20px] uppercase text-[#92ce40] font-semibold">
                              Meeting Agenda
                            </td>
                            <td className="text-[16px] lg:text-[20px] uppercase text-[#92ce40] font-semibold">
                              Members
                            </td>
                            <td className="text-[16px] lg:text-[20px] uppercase text-[#92ce40] font-semibold">
                              Date and Time
                            </td>
                          </tr>
                        </Thead>
                        <Tbody>
                          {meetings.map((meeting, i) => {
                            return (
                              <Tr key={i} borderColor="#92ce40">
                                <Td>{meeting.name}</Td>
                                <Td>{getUsers(meeting.users)}</Td>
                                <Td>
                                  {formatDateTime(
                                    meeting.meetingDate,
                                    meeting.meetingTime
                                  )}
                                </Td>
                              </Tr>
                            );
                          })}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  )}
                </TabPanel>
                <TabPanel>
                  <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                    <GridItem w="100%">
                      <FormControl display={"flex"} gap={2}>
                        <Text
                          fontSize={"20px"}
                          fontWeight={"bold"}
                          color={"#92ce40"}
                        >
                          Date:
                        </Text>
                        <Input
                          type="date"
                          placeholder="Date"
                          mb={3}
                          onChange={(e) => setMeetingDate(e.target.value)}
                          fontFamily={"Signika"}
                          borderRadius={"20px"}
                        ></Input>
                      </FormControl>
                    </GridItem>
                    <GridItem w="100%" className="hidden lg:block">
                      <FormControl display={"flex"} gap={2}>
                        <Text
                          fontSize={"20px"}
                          fontWeight={"bold"}
                          color={"#92ce40"}
                        >
                          Time:
                        </Text>
                        <Input
                          type="time"
                          placeholder="Date"
                          mb={3}
                          onChange={(e) => setMeetingTime(e.target.value)}
                          fontFamily={"Signika"}
                          borderRadius={"20px"}
                        ></Input>
                      </FormControl>
                    </GridItem>
                  </Grid>
                  <FormControl display={"flex"} gap={2} className="lg:hidden">
                    <Text
                      fontSize={"20px"}
                      fontWeight={"bold"}
                      color={"#92ce40"}
                    >
                      Time:
                    </Text>
                    <Input
                      type="time"
                      placeholder="Date"
                      mb={3}
                      onChange={(e) => setMeetingTime(e.target.value)}
                      fontFamily={"Signika"}
                      borderRadius={"20px"}
                    ></Input>
                  </FormControl>
                  <FormControl marginY={"20px"} display={"flex"} gap={2}>
                    <Text
                      fontSize={"20px"}
                      fontWeight={"bold"}
                      color={"#92ce40"}
                      width={"50%"}
                    >
                      Meeting Agenda:
                    </Text>
                    <Input
                      mb={3}
                      onChange={(e) => setMeetingName(e.target.value)}
                      fontFamily={"Signika"}
                      borderRadius={"20px"}
                    ></Input>
                  </FormControl>
                  <Box w={"100%"} display="flex" flexWrap={"wrap"}>
                    {selectedUsers.map((u) => (
                      <UserBadgeItem
                        key={u._id}
                        user={u}
                        handleFunction={() => handleDelete(u)}
                      />
                    ))}
                  </Box>

                  <FormControl marginTop={"20px"}>
                    <Input
                      placeholder="Add Users"
                      mb={1}
                      onChange={(e) => handleSearch(e.target.value)}
                      fontFamily={"Signika"}
                      borderRadius={"20px"}
                    ></Input>
                  </FormControl>
                  <Box marginY={"20px"}>
                    {loading ? (
                      <div>
                        <Spinner />
                      </div>
                    ) : (
                      searchResult
                        ?.slice(0, 4)
                        .map((user) => (
                          <UserListItem
                            key={user._id}
                            user={user}
                            handleFunction={() => handleMeeting(user)}
                          />
                        ))
                    )}
                  </Box>
                  <Button onClick={handleSubmit} backgroundColor={"#92ce40"}>
                    Schedule
                  </Button>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default MeetingList;
