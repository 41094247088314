import {
  Avatar,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Input,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { BellIcon, ChevronDownIcon, SearchIcon } from "@chakra-ui/icons";
import axios from "axios";
import ChatLoading from "../components/ChatLoading";
import UserListItem from "../components/UserAvatar/UserListItem";
import { ChatState } from "../Context/ChatProvider";

function SearchComponent() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [search, setsearch] = useState("");
  const [loading, setloading] = useState(false);
  const [seachResult, setseachResult] = useState([]);
  const [loadingChat, setloadingChat] = useState();

  const toast = useToast();

  const {
    user,
    setSelectedChat,
    chats,
    setChats,
    notification,
    setNotification,
  } = ChatState();

  const accessChat = async (userId) => {
    try {
      setloadingChat(true);
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };
      const { data } = await axios.post("/api/chat", { userId }, config);
      if (!chats.find((c) => c._id === data._id)) setChats([data, ...chats]);

      setSelectedChat(data);
      setloadingChat(false);
      onClose();
    } catch (error) {
      toast({
        title: "Failed fectching Chat",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };

  const handleSearch = async () => {
    if (!search) {
      toast({
        title: "Please Enter something in search",

        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top-left",
      });
    }

    try {
      setloading(true);
      const config = {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      };

      const { data } = await axios.get("/api/user?search=" + search, config);
      setloading(false);
      setseachResult(data);
    } catch (error) {
      toast({
        title: "Failed to load search result",

        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Tooltip label="Search Users to Chat" hasArrow placement="bottom-end">
        <Button
          _hover={{ backgroundColor: "transparent" }}
          variant={"ghost"}
          onClick={onOpen}
          color={"white"}
          width="100%"
          display="flex"
          backgroundColor={"#101010"}
          padding={"10px"}
        >
          <SearchIcon />
          <Text display={{ base: "none", md: "flex" }}>Search User</Text>
        </Button>
      </Tooltip>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose} bg={"#1d1d1d"}>
        <DrawerOverlay />
        <DrawerContent bg={"#1d1d1d"} color={"white"} borderBottomWidth={"0px"}>
          <DrawerHeader borderBottomWidth={"1px"}>Search Users</DrawerHeader>

          <DrawerBody>
            <Box display={"flex"} pb={2} pt={3}>
              <Input
                placeholder="Search by name or email"
                mr={2}
                value={search}
                onChange={(e) => setsearch(e.target.value)}
                fontFamily={"Signika"}
              ></Input>
              <Button
                onClick={handleSearch}
                bg={"#92ce40"}
                fontFamily={"Signika"}
              >
                Go
              </Button>
            </Box>
            {loading ? (
              <ChatLoading />
            ) : (
              seachResult?.map((user) => (
                <UserListItem
                  key={user._id}
                  user={user}
                  handleFunction={() => accessChat(user._id)}
                />
              ))
            )}
            {loadingChat && <Spinner ml={"auto"} display={"flex"} />}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
}

export default SearchComponent;
