import { CloseIcon } from "@chakra-ui/icons";
import { Box } from "@chakra-ui/react";
import React from "react";

const UserBadgeItem = ({ isActive, user, handleFunction }) => {
  return (
    <Box
      px={2}
      py={1}
      borderRadius={"lg"}
      m={1}
      mb={2}
      variant="solid"
      fontSize={12}
      backgroundColor="purple"
      color={"white"}
      cursor={"pointer"}
      onClick={handleFunction}
      display={"flex"}
      alignItems={"center"}
    >
      <Box
        w="8px"
        h="8px"
        borderRadius="50%"
        bg={isActive ? "green.400" : "red.400"}
        mr={2}
      />
      {user.name}
      <CloseIcon pl={1} />
    </Box>
  );
};

export default UserBadgeItem;
