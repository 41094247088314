import React from "react";
import {
  isLastMessage,
  isSameSender,
  isSameSenderMargin,
  isSameUser,
} from "../../config/ChatLogics";
import { ChatState } from "../../Context/ChatProvider";
import formatDate from "../../hooks/formatDate";

function VideoMessage({ messages, message, i }) {
  const { user } = ChatState();
  return (
    <div
      style={{
        // backgroundColor:"#ffffff",
        borderRadius: "20px",
        // padding: "5px 15px",
        maxWidth: "75%",
        marginLeft: isSameSenderMargin(messages, message, i, user._id),
        marginTop: isSameUser(messages, message, i, user._id) ? 3 : 10,
      }}
    >
      <video width="320" height="240" controls>
        <source src={message.content} type="video/mp4"></source>
      </video>
      <div
        className={`flex ${
          message.sender._id === user._id ? "justify-start" : "justify-end"
        } text-xs text-white/50`}
      >
        <p>{formatDate(message.updatedAt)}</p>
      </div>
    </div>
  );
}

export default VideoMessage;
