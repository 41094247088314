import {
  Box,
  Grid,
  GridItem,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FormBg from "../assets/img/Form_Bg.png";
import PursuitLogo from "../assets/img/Pursuit_logo.png";
import Background from "../assets/img/background.png";
import Login from "../components/Authentication/Login";
import Signup from "../components/Authentication/Signup";

const Homepage = () => {
  const history = useHistory();

  const columnCount = useBreakpointValue({ base: 1, md: 3 });
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [formBg, setFormBg] = useState(FormBg);
  useEffect(() => {
    const userinfo = JSON.parse(localStorage.getItem("userInfo"));

    if (userinfo) {
      history.push("/chats");
    }
  }, [history]);

  const handleTabChange = (index) => {
    const bg = index === 0 ? FormBg : FormBg;
    setFormBg(bg);
  };

  return (
    <Box backgroundImage={Background} height={"100vh"}>
      <Grid templateColumns={`repeat(${columnCount}, 1fr)`} height={"100%"}>
        {!isMobile && (
          <GridItem height={"100vh"}>
            <Image
              src={PursuitLogo}
              alt="log"
              width={"100%"}
              height={"100%"}
              objectFit="cover"
            />
          </GridItem>
        )}
        <GridItem colSpan={columnCount === 1 ? 1 : 2} height={"100%"}>
          <Box display={"flex"} justifyContent={"center"}>
            <Box
              backgroundImage={formBg}
              backgroundPosition={"center"}
              backgroundSize={"contain"}
              backgroundRepeat={"no-repeat"}
              padding={"10px"}
              className="h-[100vh] w-[100vw] lg:w-[40vw] 2xl:h-[90vh] 2xl:w-[40vw] "
            >
              <Box className="mt-[7rem] xl:mt-[6rem] 2xl:mt-[12rem]">
                <Text textAlign={"center"} color={"white"} fontSize={"20px"}>
                  Welcome To
                </Text>
                <Text
                  textAlign={"center"}
                  fontWeight={"bold"}
                  color={"#9cce40"}
                  fontSize={"28px"}
                >
                  MIND MERA
                </Text>
              </Box>
              <Tabs
                variant="soft-rounded"
                colorScheme="whatsapp"
                fontFamily={"Signika"}
                onChange={handleTabChange}
              >
                <TabList>
                  <Tab
                    width={"50%"}
                    background={"none !important"}
                    fontSize={"28px"}
                  >
                    Login
                  </Tab>
                  <Tab
                    width={"50%"}
                    background={"none !important"}
                    fontSize={"28px"}
                  >
                    Sign Up
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Login />
                  </TabPanel>
                  <TabPanel>
                    <Signup />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Homepage;
