import React from "react";
import {
  isLastMessage,
  isSameSender,
  isSameSenderMargin,
  isSameUser,
} from "../../config/ChatLogics";
import { ChatState } from "../../Context/ChatProvider";
import { DownloadIcon } from "@chakra-ui/icons";
import { saveAs } from "file-saver";
import formatDate from "../../hooks/formatDate";

function DocumentMessage({ messages, message, i }) {
  const { user } = ChatState();
  const getDocumentTitle = (document) => {
    const url = new URL(document);
    const pathname = url.pathname;
    const segments = pathname.split("/");
    const fileName = segments[segments.length - 1];

    return fileName;
  };

  return (
    <div
      style={{
        backgroundColor:
          message.sender._id === user._id ? "#BEE3F8" : "#B9F5D0",
        borderRadius: "5px",
        padding: "5px",
        // maxWidth: "75%",
        marginLeft: isSameSenderMargin(messages, message, i, user._id),
        marginTop: isSameUser(messages, message, i, user._id) ? 3 : 10,
      }}
      className="w-[200px] overflow-hidden lg:max-w-[75%]"
    >
      <div
        style={{
          backgroundColor:
            message.sender._id === user._id ? "#AACEE4" : "#A5E1BC",
        }}
        className="lg:py-[10px] lg:px-[20px]"
      >
        <button
          onClick={() => {
            saveAs(message.content, getDocumentTitle(message.content));
          }}
          href={message.content}
          target="_blank"
          rel="noopener noreferrer"
          download={getDocumentTitle(message.content)}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
        >
          {getDocumentTitle(message.content)}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "2px solid black",
              borderRadius: "20px",
              height: "30px",
              width: "30px",
            }}
          >
            <DownloadIcon />
          </div>
        </button>
      </div>
      <div
        className={`flex ${
          message.sender._id === user._id ? "justify-start" : "justify-end"
        } text-xs text-white/50`}
      >
        <p>{formatDate(message.updatedAt)}</p>
      </div>
    </div>
  );
}

export default DocumentMessage;
