import {
  Box,
  Grid,
  GridItem,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FormBg from "../assets/img/Form_Bg.png";
import PursuitLogo from "../assets/img/Pursuit_logo.png";
import Background from "../assets/img/background.png";
import Login from "../components/Authentication/Login";
import Signup from "../components/Authentication/Signup";
import axios from "axios";

const Homepage = () => {
  const history = useHistory();

  const columnCount = useBreakpointValue({ base: 1, md: 3 });
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [formBg, setFormBg] = useState(FormBg);

  const [verified, setVerified] = useState(0);

  const verifyToken = () => {
    if (
      localStorage.getItem("token") === "be40c8cc3a421e45a0a379c2dfc71d2a" ||
      localStorage.getItem("token") === "asfdhawehfkashfnkjas3io23oi8"
    ) {
      setVerified(1);
      const userinfo = JSON.parse(localStorage.getItem("userInfo"));
      if (userinfo) {
        history.push("/chats");
      }
    } else {
      setVerified(-1);
    }
  };

  const getIpAddress = async () => {
    const IpAddress = ["219.91.212.46", "14.98.188.246", "183.82.111.137"];

    axios.get("https://geolocation-db.com/json/").then((res) => {
      if (IpAddress.includes(res.data.IPv4)) {
        setVerified(1);
        const userinfo = JSON.parse(localStorage.getItem("userInfo"));
        if (userinfo) {
          history.push("/chats");
        }
      } else {
        verifyToken();
      }
    });
  };
  useEffect(() => {
    getIpAddress();
  }, [history]);

  const handleTabChange = (index) => {
    const bg = index === 0 ? FormBg : FormBg;
    setFormBg(bg);
  };

  if (verified === 0) {
    return;
  }

  if (verified === -1) {
    return (
      <div className="w-screen h-screen grid place-items-center">
        <div className="flex flex-col items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-14 h-14 text-red-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z"
            />
          </svg>
          <p className="text-xl font-bold">
            You are not Authorized to access this website
          </p>
        </div>
      </div>
    );
  }

  return (
    <Box backgroundImage={Background} height={"100vh"}>
      <Grid templateColumns={`repeat(${columnCount}, 1fr)`} height={"100%"}>
        {!isMobile && (
          <GridItem height={"100vh"}>
            <Image
              src={PursuitLogo}
              alt="log"
              width={"100%"}
              height={"100%"}
              objectFit="cover"
            />
          </GridItem>
        )}
        <GridItem colSpan={columnCount === 1 ? 1 : 2} height={"100%"}>
          <Box display={"flex"} justifyContent={"center"}>
            <Box
              backgroundImage={formBg}
              backgroundPosition={"center"}
              backgroundSize={"contain"}
              backgroundRepeat={"no-repeat"}
              padding={"10px"}
              className="h-[100vh] w-[100vw] lg:w-[40vw] 2xl:h-[90vh] 2xl:w-[40vw] "
            >
              <Box className="mt-[15rem] xl:mt-[6rem] 2xl:mt-[12rem]">
                <Text textAlign={"center"} color={"white"} fontSize={"20px"}>
                  Welcome To
                </Text>
                <Text
                  textAlign={"center"}
                  fontWeight={"bold"}
                  color={"#9cce40"}
                  fontSize={"28px"}
                >
                  MIND MERA
                </Text>
              </Box>
              <Tabs
                variant="soft-rounded"
                colorScheme="whatsapp"
                fontFamily={"Signika"}
                onChange={handleTabChange}
              >
                <TabList>
                  <Tab
                    width={"100%"}
                    background={"none !important"}
                    fontSize={"28px"}
                  >
                    <p className="text-[#9cce40]">Login</p>
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Login />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Homepage;
