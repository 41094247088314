import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  VStack,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LoginBtn from "../../assets/img/LogIn_Btn.png";
const Login = () => {
  const [show, setshow] = useState();
  const [Name, setName] = useState();
  const [email, setemail] = useState();
  const [password, setpassword] = useState();
  const [loading, setloading] = useState(false);
  const toast = useToast();
  const history = useHistory();
  const handel = () => {
    setshow(!show);
  };

  const formPadding = useBreakpointValue({
    base: "10px 10px",
    md: "10px 80px",
  });

  const submithandler = async () => {
    setloading(true);
    if (!email || !password) {
      toast({
        title: "Please Fill all fields",

        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setloading(false);
      return;
    }

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const { data } = await axios.post(
        "/api/user/login",
        { email, password },
        config
      );

      toast({
        title: "Login successfull",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      localStorage.setItem("userInfo", JSON.stringify(data));
      setloading(false);
      history.push("/chats");
      window.location.reload();
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 400) {
        toast({
          title: "Wrong Credentials",
          description: "Please try again",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
      } else {
        toast({
          title: "Something wrong wrong",
          description: "Please try again",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
      }
      // if (err.response && err.response.data && err.response.data.message) {
      //   toast({
      //     title: "Error1",
      //     description: err.response.data,
      //     status: "warning",
      //     duration: 5000,
      //     isClosable: true,
      //     position: "bottom",
      //   });
      // }
      setloading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      submithandler();
    }
  };
  return (
    <VStack spacing={"5px"} padding={formPadding}>
      <FormControl id="email" isRequired>
        <Input
          placeholder="Enter Your Email"
          onChange={(e) => setemail(e.target.value)}
          borderRadius={"10px"}
          backgroundColor={"#000000"}
          borderColor={"#000000"}
          color={"white"}
          padding={"25px 20px"}
          onKeyDown={(e) => {
            handleKeyDown(e);
          }}
        />
      </FormControl>
      <FormControl id="password" mt={"30px"} isRequired>
        <InputGroup>
          <Input
            type={show ? "text" : "password"}
            placeholder="Enter Your Password"
            onChange={(e) => setpassword(e.target.value)}
            borderRadius={"10px"}
            backgroundColor={"#000000"}
            borderColor={"#000000"}
            color={"white"}
            padding={"25px 20px"}
            onKeyDown={(e) => {
              handleKeyDown(e);
            }}
          />
          <InputRightElement
            width={"4.5rem"}
            paddingTop={"12px"}
            onClick={handel}
          >
            {show ? (
              <ViewOffIcon color="#95CE47" />
            ) : (
              <ViewIcon color="#95CE47" />
            )}
          </InputRightElement>
        </InputGroup>
      </FormControl>

      <Button
        mt={"10%"}
        onClick={submithandler}
        isLoading={loading}
        fontFamily={"Signika"}
        color={"white"}
        backgroundColor={"transparent"}
        _hover={{ backgroundColor: "transparent" }}
      >
        <img src={LoginBtn} alt="logoin_btn" />
      </Button>
    </VStack>
  );
};

export default Login;
