import React from "react";
import {
  isLastMessage,
  isSameSender,
  isSameSenderMargin,
  isSameUser,
} from "../../config/ChatLogics";
import { ChatState } from "../../Context/ChatProvider";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import formatDate from "../../hooks/formatDate";

function ImageMessage({ messages, message, i }) {
  const { user } = ChatState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <div
      onClick={onOpen}
      style={{
        // backgroundColor: "#ffffff",
        borderRadius: "20px",
        // padding: "10px 10px",
        maxWidth: "75%",
        marginLeft: isSameSenderMargin(messages, message, i, user._id),
        marginTop: isSameUser(messages, message, i, user._id) ? 3 : 10,
      }}
    >
      <Image
        boxSize="250px"
        objectFit="contain"
        src={message.content}
        alt="Dan Abramov"
      />

      <div
        className={`flex ${
          message.sender._id === user._id ? "justify-start" : "justify-end"
        } text-xs text-white/50`}
      >
        <p>{formatDate(message.updatedAt)}</p>
      </div>

      <Modal isOpen={isOpen} onClose={onClose} className="bg-transparent">
        <ModalOverlay />
        <ModalContent
          motion={{
            transition: "easeInOut",
            bg: "transparent", // Set the background color to transparent
          }}
          className="!bg-transparent"
        >
          <ModalBody className="!bg-transparent">
            <Image
              boxSize="500px"
              objectFit="contain"
              src={message.content}
              alt="Dan Abramov"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default ImageMessage;
