import React, { useState } from "react";
import {
  IconButton,
  useDisclosure,
  Button,
  useToast,
  Box,
  FormControl,
  Input,
  Spinner,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Grid,
  GridItem,
  Text,
  TabIndicator,
  Image,
} from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { ChatState } from "../../Context/ChatProvider";
import UserBadgeItem from "../UserAvatar/UserBadgeItem";
import axios from "axios";
import UserListItem from "../UserAvatar/UserListItem";
import { saveAs } from "file-saver";
import { DownloadIcon } from "@chakra-ui/icons";
import ImageMedia from "../ImageMedia";
import { BsFillImageFill } from "react-icons/bs";
import { HiOutlineDocumentText } from "react-icons/hi";
import { SiAudiomack } from "react-icons/si";
import { LiaLinkSolid } from "react-icons/lia";
import ImageIcon from "../../assets/icons/image.png";
import DocumentIcon from "../../assets/icons/docs.png";
import AudioIcon from "../../assets/icons/sound-waves.png";
import LinkIcon from "../../assets/icons/link.png";

const UpdateGroupChatModal = ({
  messages,
  fetchAgain,
  setFetchAgain,
  fetchmessages,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user, selectedChat, setSelectedChat, activeUsers } = ChatState();
  const [groupChatName, setGroupChatName] = useState();
  const [search, setSearch] = useState();
  const [searchResult, setSearchResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [renameLoading, setRenameLoading] = useState(false);
  const toast = useToast();

  const getDocumentTitle = (document) => {
    const url = new URL(document);
    const pathname = url.pathname;
    const segments = pathname.split("/");
    const fileName = segments[segments.length - 1];

    return fileName;
  };

  const handleAddUser = async (user1) => {
    if (selectedChat.users.find((u) => u._id === user1._id)) {
      toast({
        title: "User Already in group",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      return;
    }

    if (selectedChat.groupAdmin._id !== user._id) {
      toast({
        title: "Only Admin Can add someone",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      return;
    }
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      };
      const { data } = await axios.put(
        "/api/chat/groupadd",
        {
          chatId: selectedChat._id,
          userId: user1._id,
        },
        config
      );
      setSelectedChat(data);
      setFetchAgain(!fetchAgain);
      setLoading(false);
    } catch (error) {
      toast({
        title: "Error Occured",
        description: "Fail to Add user",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };
  const handleRemove = async (user1) => {
    if (selectedChat.groupAdmin._id !== user._id && user1._id !== user._id) {
      toast({
        title: "Only Admin Can add someone",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      return;
    }

    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      };
      const { data } = await axios.put(
        "/api/chat/groupremove",
        {
          chatId: selectedChat._id,
          userId: user1._id,
        },
        config
      );
      user1._id === user.id ? setSelectedChat() : setSelectedChat(data);

      setFetchAgain(!fetchAgain);
      fetchmessages();
      setLoading(false);
    } catch (error) {
      toast({
        title: "Error Occured",
        description: "Fail to Remove user" + error,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };
  const handleRename = async () => {
    if (!groupChatName) return;

    try {
      setRenameLoading(true);
      const config = {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      };
      const { data } = await axios.put(
        "/api/chat/rename",
        {
          chatId: selectedChat._id,
          chatName: groupChatName,
        },
        config
      );
      setSelectedChat(data);
      setFetchAgain(!fetchAgain);
      setRenameLoading(false);
    } catch (error) {
      toast({
        title: "Error Occured",
        description: "Fail to Update Group Name",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
      setRenameLoading(false);
      setGroupChatName("");
    }
  };
  const handleSearch = async (query) => {
    setSearch(query);
    if (!query) {
      return;
    }
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      };
      const { data } = await axios.get("/api/user?search=" + search, config);
      console.log(data);
      setLoading(false);
      setSearchResult(data);
    } catch (error) {
      toast({
        title: "Error Occured",
        description: "Fail to load search result",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };
  return (
    <>
      <IconButton
        background={"transparent !important"}
        display={{ base: "flex" }}
        icon={<InfoOutlineIcon color={"#92ce40"} fontSize={"25px"} />}
        onClick={onOpen}
      />

      <Drawer size={"md"} isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent background={"#282828"} color={"#ffffff"}>
          <DrawerCloseButton />
          <DrawerHeader>{selectedChat.chatName}</DrawerHeader>

          <DrawerBody>
            <p>Users</p>
            <Box w={"100%"} display={"flex"} flexWrap={"wrap"} pb={3}>
              {selectedChat.users.map((u) => {
                const isActive = activeUsers.find(
                  (activeUser) => activeUser._id === u._id
                );
                return (
                  <UserBadgeItem
                    key={u._id}
                    isActive={isActive}
                    user={u}
                    handleFunction={() => handleRemove(u)}
                  />
                );
              })}

              <FormControl marginTop={"20px"}>
                <Input
                  placeholder="Group Name"
                  mb={3}
                  value={groupChatName}
                  onChange={(e) => setGroupChatName(e.target.value)}
                  borderRadius={"20px"}
                ></Input>
                <Button
                  variant={"solid"}
                  colorScheme="teal"
                  ml={1}
                  mb={3}
                  isLoading={renameLoading}
                  onClick={handleRename}
                >
                  Update
                </Button>
              </FormControl>
            </Box>

            <FormControl>
              <Input
                placeholder="Add Users To group"
                mb={1}
                onChange={(e) => handleSearch(e.target.value)}
                borderRadius={"20px"}
              ></Input>
            </FormControl>

            {loading ? (
              <Spinner size={"lg"} />
            ) : (
              searchResult?.map((user) => (
                <UserListItem
                  key={user._id}
                  user={user}
                  handleFunction={() => handleAddUser(user)}
                />
              ))
            )}
            <Tabs
              marginTop={"20px"}
              isFitted
              colorScheme="whatsapp"
              variant="unstyled"
            >
              <TabList>
                <Tab>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Image src={ImageIcon} />
                    <Text color={"#95ce47"}>Media</Text>
                  </Box>
                </Tab>
                <Tab>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Image src={DocumentIcon} />
                    <Text color="#95ce47">Document</Text>
                  </Box>
                </Tab>
                <Tab>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Image src={AudioIcon} />
                    <Text color="#95ce47">Audio</Text>
                  </Box>
                </Tab>
                <Tab>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Image src={LinkIcon} />
                    <Text color="#95ce47">Links</Text>
                  </Box>
                </Tab>
              </TabList>
              <TabIndicator
                mt="-1.5px"
                height="2px"
                bg="green.500"
                borderRadius="1px"
              />

              <TabPanels>
                <TabPanel>
                  <Grid templateColumns="repeat(3, 1fr)" gap={2}>
                    {messages
                      ?.filter(
                        (m) =>
                          m.messageType === "image" || m.messageType === "video"
                      )
                      .reverse()
                      .map((m, i) => (
                        <GridItem key={i} w="100%" h="20">
                          {m.messageType === "image" ? (
                            <ImageMedia m={m} />
                          ) : (
                            <video
                              controls
                              width="100%"
                              height="100%"
                              objectFit="contain"
                            >
                              <source src={m.content} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          )}
                        </GridItem>
                      ))}
                  </Grid>
                </TabPanel>
                <TabPanel>
                  <Grid templateColumns="repeat(1, 1fr)" gap={2}>
                    {messages
                      ?.filter((m) => m.messageType === "document")
                      .reverse()
                      .map((m, i) => (
                        <GridItem w="100%" h="20">
                          <div
                            style={{
                              backgroundColor: "#A5E1BC",
                              padding: "10px 20px",
                            }}
                          >
                            <button
                              onClick={() => {
                                saveAs(m.content, getDocumentTitle(m.content));
                              }}
                              href={m.content}
                              target="_blank"
                              rel="noopener noreferrer"
                              download={getDocumentTitle(m.content)}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "20px",
                                color: "black",
                              }}
                            >
                              {getDocumentTitle(m.content)}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  border: "2px solid black",
                                  borderRadius: "20px",
                                  height: "30px",
                                  width: "30px",
                                }}
                              >
                                <DownloadIcon />
                              </div>
                            </button>
                          </div>
                        </GridItem>
                      ))}
                  </Grid>
                </TabPanel>
                <TabPanel>
                  <Grid templateColumns="repeat(1, 1fr)" gap={2}>
                    {messages
                      ?.filter((m) => m.messageType === "audio")
                      .reverse()
                      .map((m, i) => (
                        <GridItem key={i} w="100%" h="20">
                          <audio
                            controls
                            style={{ width: "100%", height: "100%" }}
                          >
                            <source src={m.content} type="audio/mp3" />
                            <source src={m.content} type="audio/mpeg" />
                            <source src={m.content} type="audio/ogg" />
                          </audio>
                        </GridItem>
                      ))}
                  </Grid>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </DrawerBody>
          <DrawerFooter>
            <Button colorScheme="red" mr={3} onClick={() => handleRemove(user)}>
              Leave Group
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default UpdateGroupChatModal;
