import React, { useState, useEffect } from "react";
import { ChatState } from "../Context/ChatProvider";
import { Box } from "@chakra-ui/react";
import MyChats from "../components/MyChats";
import ChatBox from "../components/ChatBox";
import SideDrawer from "../components/miscellaneous/SideDrawer";
import socket from "../components/miscellaneous/socket";

const Chatpage = () => {
  const { user, setActiveUsers, setSocketConnected } = ChatState();
  const [fetchAgain, setFetchAgain] = useState();
  const [verified, setVerified] = useState(0);

  const verifyToken = () => {
    if (
      localStorage.getItem("token") === "be40c8cc3a421e45a0a379c2dfc71d2a" ||
      localStorage.getItem("token") === "asfdhawehfkashfnkjas3io23oi8"
    ) {
      setVerified(1);
    } else {
      setVerified(-1);
    }
  };

  const verifyIP = async () => {
    const IpAddress = ["219.91.212.46", "14.98.188.246", "183.82.111.137"];
    const response = await fetch("https://geolocation-db.com/json/");
    const data = await response.json();

    console.log(data.IPv4);

    if (IpAddress.includes(data.IPv4)) {
      console.log("success");
      setVerified(1);
    } else {
      verifyToken();
    }
  };

  useEffect(() => {
    verifyIP();
  }, []);

  useEffect(() => {
    if (user === undefined || user === null) {
      return;
    }

    socket.emit("setup", user);
    socket.on("connected", () => {
      setSocketConnected(true);
    });

    socket.on("activeUsersUpdate", (updatedActiveUsers) => {
      const uniqueIds = {};

      const uniqueUsers = updatedActiveUsers.filter((user) => {
        if (!uniqueIds[user._id]) {
          uniqueIds[user._id] = true;
          return true;
        }
        return false;
      });

      setActiveUsers(uniqueUsers);
    });

    return () => {
      socket.disconnect();
    };
  }, [user]);

  if (verified === 0) {
    return;
  }

  if (verified === -1) {
    return (
      <div className="w-screen h-screen grid place-items-center">
        <div className="flex flex-col items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-14 h-14 text-red-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z"
            />
          </svg>
          <p className="text-xl font-bold">
            You are not Authorized to access this website
          </p>
        </div>
      </div>
    );
  }

  return (
    <div style={{ height: "100vh", width: "100%", backgroundColor: "#000000" }}>
      <Box
        display="flex"
        justifyContent={"space-between"}
        w={"100%"}
        // h={"91.5vh"}
        // p={"10px"}
      >
        {user && <SideDrawer />}
        {user && <MyChats fetchAgain={fetchAgain} />}
        {user && (
          <ChatBox fetchAgain={fetchAgain} setFetchAgain={setFetchAgain} />
        )}
      </Box>
    </div>
  );
};

export default Chatpage;
