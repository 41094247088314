import {
  IconButton,
  Text,
  Input,
  Editable,
  EditableInput,
  EditablePreview,
  useEditableControls,
  ButtonGroup,
  Flex,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import React from "react";
import { EditIcon, CheckIcon, CloseIcon } from "@chakra-ui/icons";

function EditEmail({ title, value, setValue, updateValue }) {
  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup
        backgroundColor={"transparent"}
        _hover={{ backgroundColor: "transparent" }}
        justifyContent="center"
        size="sm"
      >
        <IconButton
          backgroundColor={"transparent"}
          _hover={{ backgroundColor: "transparent" }}
          icon={<CheckIcon color="green" />}
          {...getSubmitButtonProps()}
        />
        <IconButton
          backgroundColor={"transparent"}
          _hover={{ backgroundColor: "transparent" }}
          icon={<CloseIcon color="red" />}
          {...getCancelButtonProps()}
        />
      </ButtonGroup>
    ) : (
      <Flex justifyContent="center">
        <IconButton
          backgroundColor={"transparent"}
          _hover={{ backgroundColor: "transparent" }}
          size="sm"
          icon={<EditIcon color="green" fontSize={"20px"} />}
          {...getEditButtonProps()}
        />
      </Flex>
    );
  }
  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <Grid templateColumns="repeat(2, 1fr)" gap={2}>
        <GridItem display={'flex'} justifyContent={'end'} alignItems={'center'}>
          <Text fontSize={"xl"} fontWeight={'bold'}>{title}:</Text>
        </GridItem>
        <GridItem>
          <Editable
            textAlign="center"
            defaultValue={value}
            color={"#92ce40"}
            fontSize="20px"
            display={"flex"}
            fontWeight={'bold'}
            flexDirection={"row"}
            alignItems={"center"}
            gap={"10px"}
            isPreviewFocusable={false}
            onSubmit={() => {
              updateValue();
            }}
          >
            <EditablePreview />
            <Input
              as={EditableInput}
              onChange={(e) => setValue(e.target.value)}
            />
            <EditableControls />
          </Editable>
        </GridItem>
      </Grid>
    </div>
  );
}

export default EditEmail;
