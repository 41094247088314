import AWS from "aws-sdk";

const S3_BUCKET = "mindmera";
const REGION = "ap-south-1";
const ACCESS_KEY = "AKIAWM673MLIREVFWXPV";
const SECRET_ACCESS_KEY = "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";

AWS.config.update({
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
});

const bucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export { bucket, S3_BUCKET };
