import { CloseIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import {
  IconButton,
  useDisclosure,
  Box,
  Image,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Grid,
  GridItem,
  Button,
  TabIndicator,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { DownloadIcon } from "@chakra-ui/icons";
import ImageMedia from "../ImageMedia";
import { ChatState } from "../../Context/ChatProvider";
import { CalendarIcon } from "@chakra-ui/icons";
import { BsFillImageFill } from "react-icons/bs";
import { SiAudiomack } from "react-icons/si";
import { HiOutlineDocumentText } from "react-icons/hi";
import { LiaLinkSolid } from "react-icons/lia";
import axios from "axios";
import Divider from "../../assets/img/Divider.png";

import ImageIcon from "../../assets/icons/image.png";
import DocumentIcon from "../../assets/icons/docs.png";
import AudioIcon from "../../assets/icons/sound-waves.png";
import LinkIcon from "../../assets/icons/link.png";

const ChatProfileModal = ({ messages, userDetails, children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const getDocumentTitle = (document) => {
    const url = new URL(document);
    const pathname = url.pathname;
    const segments = pathname.split("/");
    const fileName = segments[segments.length - 1];

    return fileName;
  };

  const { selectedChat, activeUsers, user } = ChatState();

  const [upcomingMeetings, setUpComingMeetings] = useState(null);

  const getMeetings = () => {
    const config = {
      headers: {
        Authorization: "Bearer " + user.token,
      },
    };
    axios
      .post("/api/meeting/user", { user: userDetails }, config)
      .then((res) => {
        console.log(res.data);
        console.log(res.data === null);
        setUpComingMeetings(res.data);
      });
  };
  useEffect(() => {
    getMeetings();
  }, [userDetails]);

  const getStatus = () => {
    if (!selectedChat.isGroupChat) {
      const isActive = activeUsers.find(
        (activeUser) => activeUser._id === userDetails._id
      );

      return isActive;
    }
  };

  const formatDate = (string) => {
    const date = new Date(string);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  const formatTime = (string) => {
    const timeParts = string.split(":");

    const hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);

    const period = hours >= 12 ? "pm" : "am";

    const formattedTime = `${hours}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;
    return formattedTime;
  };
  return (
    <>
      {children ? (
        <span onClick={onOpen}>{children}</span>
      ) : (
        <IconButton
          d={{ base: "flex" }}
          icon={<InfoOutlineIcon />}
          onClick={onOpen}
          background={"transparent"}
          color={"#92ce40"}
          fontSize={"25px"}
        />
      )}
      <Drawer size={"md"} isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent background={"#282828"} color={"#ffffff"}>
          <DrawerCloseButton color="#92ce40" fontWeight={"bold"} />

          <DrawerBody>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Image
                src={userDetails.pic}
                borderRadius={"full"}
                boxSize={"150px"}
                alt={userDetails.name}
                objectFit={"cover"}
              />
              <Text color={"white"} fontSize={"28px"} fontWeight={"bold"}>
                {userDetails.name}
              </Text>
              <Box display={"flex"} alignItems={"center"}>
                <Box
                  backgroundColor={
                    getStatus(userDetails) ? "#8dc73d" : "red.600"
                  }
                  height="10px"
                  width="10px"
                  borderRadius="50%"
                  marginRight={"5px"}
                ></Box>
                <Text
                  color={getStatus(userDetails) ? "#8dc73d" : "red.600"}
                  fontSize={"16px"}
                >
                  {getStatus(userDetails) ? "Active Now" : "Away"}
                </Text>
              </Box>
            </Box>
            {upcomingMeetings?.length !== 0 && (
              <Image
                src={Divider}
                marginTop={"10px"}
                height={"3px"}
                alt="Diver"
                objectFit={"contain"}
              />
            )}
            {upcomingMeetings?.map((meeting) => {
              return (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  gap={"2"}
                  paddingY={"10px"}
                >
                  <CalendarIcon fontSize={"50px"} />
                  <Box>
                    <Text fontWeight={"500"} fontSize={"18px"}>
                      Meeting Scheduled on
                    </Text>
                    <Text fontSize={"18px"} display={"flex"} gap={2}>
                      <Text fontWeight={"500"} color={"yellow.400"}>
                        {formatDate(meeting.meetingDate)}
                      </Text>{" "}
                      @{" "}
                      <Text color={"yellow.400"}>
                        {formatTime(meeting.meetingTime)}
                      </Text>
                    </Text>
                  </Box>
                </Box>
              );
            })}

            <Image
              src={Divider}
              marginTop={"10px"}
              height={"3px"}
              alt="Diver"
              objectFit={"contain"}
            />

            <Tabs isFitted colorScheme="whatsapp">
              <TabList>
                <Tab>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Image src={ImageIcon} />
                    <Text color={"#95ce47"}>Media</Text>
                  </Box>
                </Tab>
                <Tab>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Image src={DocumentIcon} />
                    <Text color="#95ce47">Document</Text>
                  </Box>
                </Tab>
                <Tab>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Image src={AudioIcon} />
                    <Text color="#95ce47">Audio</Text>
                  </Box>
                </Tab>
                <Tab>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Image src={LinkIcon} />
                    <Text color="#95ce47">Links</Text>
                  </Box>
                </Tab>
              </TabList>
              <TabIndicator
                mt="-1.5px"
                height="2px"
                bg="green.500"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel>
                  <Grid templateColumns="repeat(3, 1fr)" gap={2}>
                    {messages
                      ?.filter(
                        (m) =>
                          m.messageType === "image" || m.messageType === "video"
                      )
                      .reverse()
                      .map((m, i) => (
                        <GridItem key={i} w="100%" h="100px" overflow={"auto"}>
                          {m.messageType === "image" ? (
                            <ImageMedia m={m} />
                          ) : (
                            <video
                              controls
                              width="100%"
                              height="100%"
                              objectFit="contain"
                            >
                              <source src={m.content} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          )}
                        </GridItem>
                      ))}
                  </Grid>
                </TabPanel>
                <TabPanel>
                  <Grid templateColumns="repeat(1, 1fr)" gap={2}>
                    {messages
                      ?.filter((m) => m.messageType === "document")
                      .reverse()
                      .map((m, i) => (
                        <GridItem w="100%" h="20">
                          <div
                            style={{
                              backgroundColor: "#A5E1BC",
                              padding: "10px 20px",
                            }}
                          >
                            <button
                              onClick={() => {
                                saveAs(m.content, getDocumentTitle(m.content));
                              }}
                              href={m.content}
                              target="_blank"
                              rel="noopener noreferrer"
                              download={getDocumentTitle(m.content)}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "20px",
                                color: "black",
                              }}
                            >
                              {getDocumentTitle(m.content)}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  border: "2px solid black",
                                  borderRadius: "20px",
                                  height: "30px",
                                  width: "30px",
                                }}
                              >
                                <DownloadIcon />
                              </div>
                            </button>
                          </div>
                        </GridItem>
                      ))}
                  </Grid>
                </TabPanel>
                <TabPanel>
                  <Grid templateColumns="repeat(1, 1fr)" gap={2}>
                    {messages
                      ?.filter((m) => m.messageType === "audio")
                      .reverse()
                      .map((m, i) => (
                        <GridItem key={i} w="100%" h="20">
                          <audio
                            controls
                            style={{ width: "100%", height: "100%" }}
                          >
                            <source src={m.content} type="audio/mp3" />
                            <source src={m.content} type="audio/mpeg" />
                            <source src={m.content} type="audio/ogg" />
                          </audio>
                        </GridItem>
                      ))}
                  </Grid>
                </TabPanel>
                <TabPanel></TabPanel>
              </TabPanels>
            </Tabs>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default ChatProfileModal;
