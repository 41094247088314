const formatDate = (date) => {
  const dateTime = new Date(date);

  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return dateTime.toLocaleString("en-US", options);
};

export default formatDate;
