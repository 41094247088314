import React, { useEffect } from "react";

function Token() {
  useEffect(() => {
    localStorage.setItem("token", "be40c8cc3a421e45a0a379c2dfc71d2a");
  }, []);

  return (
    <div className="w-screen h-screen grid place-items-center">
      <div>
        <p className="text-xl font-bold text-center">
          You are now authorized to be the member of Mind Mera
        </p>
      </div>
    </div>
  );
}

export default Token;
