import { Button } from "@chakra-ui/react";
import "./App.css";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import Homepage from "./Pages/Homepage";
import Chatpage from "./Pages/Chatpage";
import Login from "./Pages/Login";
import Token from "./Pages/Token";
import Token1 from "./Pages/Token1";

// function App() {
//   const currentPath = window.location.pathname;
//   return (
//     <div className={`App ${currentPath === "/" ? "" : "chatpage-background"}`}>
//       <Route path="/" component={Homepage} exact />
//       <Route path="/chats" component={Chatpage} />
//     </div>
//   );
// }

function App() {
  const currentPath = window.location.pathname;
  return (
    <div>
      <Route exact path="/" component={Login} />
      <Route path="/signup" component={Homepage} />
      <Route path="/chats" component={Chatpage} />
      <Route path="/token" component={Token} />
      <Route path="/token1" component={Token1} />
    </div>
  );
}

export default App;
