import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spinner,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";
import { ChatState } from "../Context/ChatProvider";
import UserBadgeItem from "../components/UserAvatar/UserBadgeItem";
import UserListItem from "./UserAvatar/UserListItem";

const BroadCastModal = ({ isOpen, closeModal }) => {
  const [loading, setLoading] = useState(false);
  const { user, broadCast, setBroadCast } = ChatState();

  const [title, setTitle] = useState();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [search, setSearch] = useState();
  const [all, setAll] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [content, setContent] = useState();
  const [disappearDate, setDisappearDate] = useState();

  const toast = useToast();

  const handleSearch = async (query) => {
    setSearch(query);
    if (!query) {
      return;
    }
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      };
      const { data } = await axios.get("/api/user?search=" + search, config);

      setLoading(false);
      setSearchResult(data);
    } catch (error) {
      toast({
        title: "Error Occured",
        description: "Fail to load search result",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };

  const handleDelete = (userTodelete) => {
    setSelectedUsers(
      selectedUsers.filter((sel) => sel._id !== userTodelete._id)
    );
  };

  const handleBroadCast = (userToadd) => {
    if (selectedUsers.includes(userToadd)) {
      toast({
        title: "User already Added",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    setSelectedUsers([...selectedUsers, userToadd]);
  };

  const handleSubmit = async () => {
    if (!title) {
      toast({
        title: "Please fill title",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      };
      const { data } = await axios.post(
        "/api/broadcast/create",
        {
          title: title,
          users: JSON.stringify(selectedUsers.map((u) => u._id)),
          content: content,
          all: all,
          disappearDate: disappearDate,
        },
        config
      );

      setBroadCast([data, ...broadCast]);
      closeModal();

      toast({
        title: "Broad has been Created",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    } catch (error) {
      toast({
        title: "Error Occured",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };

  return (
    <>
      <Modal size={"xl"} isOpen={isOpen} onClose={closeModal} isCentered>
        <ModalOverlay />
        <ModalContent
          background={"#000000"}
          color={"white"}
          borderRadius={"50px"}
          margin={"10px"}
        >
          <ModalCloseButton
            color={"#92ce40"}
            fontWeight={"bold"}
            fontSize={"20px"}
            margin={"20px"}
          />
          <ModalBody
            display="flex"
            flexDir={"column"}
            border={"2px solid #92ce40"}
            borderRadius={"50px"}
          >
            <Box overflow={"auto"}>
              <Text
                textAlign={"center"}
                fontWeight={"bold"}
                color={" #92ce40"}
                marginTop={"10px"}
                fontSize={"20px"}
              >
                Create BroadCast
              </Text>
              <Box marginTop={"10px"}>
                <FormControl>
                  <Input
                    placeholder="Title"
                    mb={3}
                    onChange={(e) => setTitle(e.target.value)}
                    fontFamily={"Signika"}
                    borderRadius={"20px"}
                  ></Input>
                </FormControl>
                <FormControl>
                  <Textarea
                    placeholder="Content"
                    mb={3}
                    onChange={(e) => setContent(e.target.value)}
                    fontFamily={"Signika"}
                    borderRadius={"20px"}
                  ></Textarea>
                </FormControl>

                <FormControl
                  marginTop={"10px"}
                  display={"flex"}
                  alignItems={"center"}
                  gap={"2"}
                >
                  <FormLabel>Disappear Date</FormLabel>
                  <Input
                    placeholder="Add Users"
                    type="datetime-local"
                    mb={1}
                    onChange={(e) => setDisappearDate(e.target.value)}
                    fontFamily={"Signika"}
                    borderRadius={"20px"}
                  ></Input>
                </FormControl>
                <FormControl
                  marginTop={"10px"}
                  display={"flex"}
                  alignItems={"center"}
                  gap={"2"}
                >
                  <Checkbox
                    placeholder="Content"
                    mb={3}
                    isChecked={all}
                    onChange={(e) => setAll(e.target.checked)}
                    fontFamily={"Signika"}
                    borderRadius={"20px"}
                  ></Checkbox>
                  <Text marginTop={"-10px"}>All</Text>
                </FormControl>
                {!all && (
                  <FormControl marginTop={"20px"}>
                    <Input
                      placeholder="Add Users"
                      mb={1}
                      onChange={(e) => handleSearch(e.target.value)}
                      fontFamily={"Signika"}
                      borderRadius={"20px"}
                    ></Input>
                  </FormControl>
                )}

                {!all && (
                  <Box w={"100%"} display="flex" flexWrap={"wrap"}>
                    {selectedUsers.map((u) => (
                      <UserBadgeItem
                        key={u._id}
                        user={u}
                        handleFunction={() => handleDelete(u)}
                      />
                    ))}
                    {loading ? (
                      <div>
                        <Spinner />
                      </div>
                    ) : (
                      searchResult
                        ?.slice(0, 4)
                        .map((user) => (
                          <UserListItem
                            key={user._id}
                            user={user}
                            handleFunction={() => handleBroadCast(user)}
                          />
                        ))
                    )}
                  </Box>
                )}
              </Box>
            </Box>
            <ModalFooter>
              <Button
                background={"#92ce40"}
                fontFamily={"Signika"}
                color={"white"}
                mr={3}
                mb={3}
                onClick={handleSubmit}
              >
                Create
              </Button>
            </ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BroadCastModal;
