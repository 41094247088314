import React, { useEffect, useState } from "react";
import { ChatState } from "../Context/ChatProvider";
import {
  Box,
  Button,
  Image,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { AddIcon, HamburgerIcon } from "@chakra-ui/icons";
import { getSender, getSenderFull } from "../config/ChatLogics";
import ChatLoading from "./ChatLoading";
import GroupChatModal from "./miscellaneous/GroupChatModal";
import ActiveUsers from "./ActiveUsers";
import ProfileUser from "../assets/img/profile-user.png";
import SearchComponent from "./SearchComponent";
import Divider from "../assets/img/Divider.png";

const MyChats = ({ fetchAgain }) => {
  const [loggedUser, setLoggedUser] = useState();
  const {
    user,
    setuser,
    selectedChat,
    setSelectedChat,
    chats,
    setChats,
    setShowSidebar,
  } = ChatState();

  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const fetchChats = async (userId) => {
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      };
      const { data } = await axios.get("/api/chat", config);
      setChats(data);
      console.log(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: "Failed fectching Chat",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };
  useEffect(() => {
    setLoggedUser(JSON.parse(localStorage.getItem("userInfo")));
    fetchChats();
  }, [fetchAgain]);

  return (
    <Box
      display={{ base: selectedChat ? "none" : "flex", md: "flex" }}
      flexDir={"column"}
      alignItems={"center"}
      p={3}
      bg={"#1d1d1d"}
      w={{ base: "100%", md: "31%" }}
      borderRadius={"lg"}
      height={"99vh"}
      borderWidth={"0px"}
      fontFamily={"Signika"}
    >
      <Box display={"flex"} width={"100%"} gap={"10px"}>
        <Box
          height="80px"
          width="80px"
          borderRadius={"50%"}
          borderWidth={"4px"}
          borderColor={"#92ce40"}
        >
          <Image
            src={user.pic}
            alt="profile"
            height="100%"
            width="100%"
            objectFit={"cover"}
            borderRadius={"50%"}
          />
        </Box>
        <Box>
          <Text fontSize={"35px"} fontWeight={"bold"} color={"#92ce40"}>
            {user.name}
          </Text>
          <Box display={"flex"} flexDirection={"row"}>
            <Text color={"white"}>Status:</Text>
            <Box display={"flex"} alignItems={"center"}>
              <Box
                backgroundColor={"#8dc73d"}
                height="10px"
                width="10px"
                borderRadius="50%"
                marginX={"5px"}
              ></Box>
              <Text color={"#8dc73d"} fontSize={"16px"}>
                Active Now
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>

      <Image
        src={Divider}
        marginTop={"10px"}
        height={"3px"}
        alt="Diver"
        objectFit={"contain"}
      />

      <Box
        marginTop={"10px"}
        display={"flex"}
        width={"100%"}
        justifyContent={"space-between"}
        gap={"10"}
      >
        <div className="lg:hidden">
          <Button
            d="flex"
            fontSize={{ base: "17px", md: "10px", lg: "17px" }}
            background={"#101010"}
            color={"white"}
            className="lg:hidden"
            onClick={(e) => {
              setShowSidebar(true);
            }}
          >
            <HamburgerIcon />
          </Button>
        </div>
        <SearchComponent flex={"1"} />
        <GroupChatModal>
          <Button
            d="flex"
            fontSize={{ base: "17px", md: "10px", lg: "17px" }}
            background={"#101010"}
            color={"white"}
          >
            <AddIcon />
          </Button>
        </GroupChatModal>
      </Box>

      <Box
        display={"flex"}
        flexDir={"column"}
        p={3}
        bg={"#1d1d1d"}
        w={"100%"}
        h={"100%"}
        borderRadius={"lg"}
        overflow={"hidden"}
        color={"white"}
      >
        {loading && (
          <Spinner
            size={"xl"}
            w={20}
            h={20}
            alignSelf={"center"}
            margin={"auto"}
            color="#92ce40"
          />
        )}
        {chats && !loading ? (
          <Stack overflowY={"scroll"} overflowX={"hidden"}>
            {chats.map((chat) => (
              <Box
                onClick={() => setSelectedChat(chat)}
                cursor={"pointer"}
                bg={selectedChat === chat ? "#92ce40" : "#0e0e0e"}
                color={selectedChat === chat ? "white" : "white"}
                px={3}
                py={2}
                borderRadius={"lg"}
                key={chat._id}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box display={"flex"} alignItems={"cener"}>
                  <Image
                    src={
                      chat.isGroupChat
                        ? ProfileUser
                        : getSenderFull(user, chat.users).pic
                    }
                    height={"40px"}
                    width={"40px"}
                    objectFit={"cover"}
                    borderRadius={"50%"}
                  />
                  <Box marginLeft={"10px"}>
                    <Text
                      fontSize={"20px"}
                      color={selectedChat === chat ? "#ffffff" : "#92ce40"}
                    >
                      {!chat.isGroupChat
                        ? getSender(loggedUser, chat.users)
                        : chat.chatName}
                    </Text>
                    {chat.latestMessage && (
                      <Text>
                        {chat.latestMessage?.sender?._id === user?._id
                          ? "You:"
                          : chat?.latestMessage?.sender?.name + ":"}
                        {chat?.latestMessage?.content?.length > 100
                          ? chat.latestMessage?.content?.slice(0, 100) + "...."
                          : chat.latestMessage?.content}
                      </Text>
                    )}
                  </Box>
                </Box>
                {chat?.messageCount > 0 && (
                  <Box
                    backgroundColor={"green.600"}
                    height={"30px"}
                    width={"30px"}
                    borderRadius={"50%"}
                    display={"grid"}
                    placeItems={"center"}
                  >
                    {chat?.messageCount}
                  </Box>
                )}
              </Box>
            ))}
          </Stack>
        ) : (
          <div>Loading ...</div>
        )}
      </Box>
    </Box>
  );
};

export default MyChats;
