import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const { createContext, useContext, useEffect, useState } = require("react");

const ChatContext = createContext();
const ChatProvider = ({ children }) => {
  const [user, setuser] = useState();
  const [selectedChat, setSelectedChat] = useState();
  const [chats, setChats] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [notification, setNotification] = useState([]);
  const [socketConnected, setSocketConnected] = useState(false);
  const [meetings, setMeetings] = useState([]);
  const [broadCast, setBroadCast] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const userinfo = JSON.parse(localStorage.getItem("userInfo"));
    setuser(userinfo);

    console.log(history.location.pathname);

    if (!userinfo && history.location.pathname !== "/signup") {
      history.push("/");
    }
  }, [history]);

  return (
    <ChatContext.Provider
      value={{
        user,
        setuser,
        selectedChat,
        setSelectedChat,
        chats,
        setChats,
        activeUsers,
        setActiveUsers,
        notification,
        setNotification,
        meetings,
        setMeetings,
        socketConnected,
        setSocketConnected,
        broadCast,
        setBroadCast,
        showSidebar,
        setShowSidebar,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const ChatState = () => {
  return useContext(ChatContext);
};

export default ChatProvider;
