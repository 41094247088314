import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  Image,
  InputGroup,
  InputRightElement,
  VStack,
  VisuallyHiddenInput,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { S3_BUCKET, bucket } from "../../config";

const Signup = () => {
  const [show, setshow] = useState();
  const [name, setname] = useState();
  const [employeeId, setEmplyeeId] = useState();
  const [email, setemail] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [password, setpassword] = useState();
  const [confirmpassword, setconfirmpassword] = useState();
  const [pic, setpic] = useState(null);
  const [loading, setloading] = useState(false);
  const toast = useToast();
  const history = useHistory();

  const profileImageRef = useRef(null);

  const handel = () => {
    setshow(!show);
  };
  const postDetails = (pics) => {
    setloading(true);
    if (pics === undefined) {
      toast({
        title: "Image Error",
        description: "please select image",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      return;
    }
    if (
      pics.type === "image/jpeg" ||
      pics.type === "image/jpg" ||
      pics.type === "image/png"
    ) {
      const timestamp = new Date().toISOString().replace(/[^a-zA-Z0-9]/g, "");
      const randomString = Math.random().toString(36).substring(2, 8);
      const uniqueFileName = `${timestamp}_${randomString}_${pics.name}`;

      const params = {
        ACL: "public-read",
        Body: pics,
        Bucket: S3_BUCKET,
        Key: "profile_images/" + uniqueFileName,
      };

      bucket.upload(params, (err, data) => {
        if (err) {
          console.error("Error uploading file:", err);
        } else {
          console.log("File uploaded successfully:", data);
          console.log(data.Location);
          setpic(data.Location);
        }
        setloading(false);
      });
    } else {
      toast({
        title: "Image Error",
        description: "please select image",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setloading(false);
    }
  };
  const submithandler = async () => {
    setloading(true);

    if (
      !name ||
      !email ||
      !password ||
      !confirmpassword ||
      !employeeId ||
      !mobileNumber
    ) {
      toast({
        title: "Please Fill all fields",

        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setloading(false);
      return;
    }

    if (password !== confirmpassword) {
      toast({
        title: "Password Doesnt Match",

        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setloading(false);
      return;
    }
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      console.log("Name:+" + name);
      const { data } = await axios.post(
        "/api/user",
        { name, email, password, pic, employeeId, mobileNumber },
        config
      );

      toast({
        title: "Registeration successfull",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      localStorage.setItem("userInfo", JSON.stringify(data));
      setloading(false);
      history.pushState("/chats");
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        toast({
          title: "Error1",
          description: err.response.data,
          status: "warning",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
      }
      setloading(false);
    }
  };

  const openFileManager = () => {
    profileImageRef.current.click();
  };
  return (
    <VStack className="mt-[-20px] 2xl:mt-0 px-[4rem] 2xl:px-[5rem]">
      <FormControl id="first-name" isRequired>
        <input
          placeholder="Enter Your Name"
          onChange={(e) => setname(e.target.value)}
          className="py-[10px] h-[10px] 2xl:h-auto 2xl:py-[6px] border-[10px] border-black bg-black text-white w-full rounded-lg"
        />
      </FormControl>
      <FormControl id="employee_id" isRequired>
        <input
          placeholder="Enter Your Employee Id"
          onChange={(e) => setEmplyeeId(e.target.value)}
          className="py-[10px] h-[10px] 2xl:h-auto 2xl:py-[6px] border-[10px] border-black bg-black text-white w-full rounded-lg"
        />
      </FormControl>
      <FormControl id="email" isRequired>
        <input
          placeholder="Enter Your Email"
          onChange={(e) => setemail(e.target.value)}
          className="py-[10px] h-[10px] 2xl:h-auto 2xl:py-[6px] border-[10px] border-black bg-black text-white w-full rounded-lg"
        />
      </FormControl>
      <FormControl id="mobileNumber" isRequired>
        <input
          placeholder="Enter Your Mobile Number"
          onChange={(e) => setMobileNumber(e.target.value)}
          className="py-[10px] h-[10px] 2xl:h-auto 2xl:py-[6px] border-[10px] border-black bg-black text-white w-full rounded-lg"
        />
      </FormControl>
      <FormControl id="password" isRequired>
        <InputGroup>
          <input
            type={show ? "text" : "password"}
            placeholder="Enter Your Password"
            onChange={(e) => setpassword(e.target.value)}
            className="py-[10px] h-[10px] 2xl:h-auto 2xl:py-[6px] border-[10px] border-black bg-black text-white w-full rounded-lg"
          />
          <InputRightElement width={"4.5rem"} onClick={handel}>
            {show ? <ViewOffIcon /> : <ViewIcon />}
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <FormControl id="confirmpassword" isRequired>
        <InputGroup>
          <input
            type="password"
            placeholder="Confirm Password"
            onChange={(e) => setconfirmpassword(e.target.value)}
            className="py-[10px] h-[10px] 2xl:h-auto 2xl:py-[6px] border-[10px] border-black bg-black text-white w-full rounded-lg"
          />
        </InputGroup>
      </FormControl>

      <FormControl id="pic" isRequired>
        {!pic && (
          <Button backgroundColor={"#95CE47"} onClick={openFileManager}>
            Choose Profile Image
          </Button>
        )}
        {pic && <Image src={pic} alt="profile" height={"80px"} />}

        <VisuallyHiddenInput
          type="file"
          ref={profileImageRef}
          p={1.5}
          accept="image/*"
          onChange={(e) => postDetails(e.target.files[0])}
          borderRadius={"20px"}
          backgroundColor={"#000000"}
          borderColor={"#000000"}
          color={"white"}
        />
      </FormControl>

      <Button
        background={"#95CE47"}
        width={"100%"}
        mt={"5%"}
        onClick={submithandler}
        isLoading={loading}
        fontFamily={"Signika"}
      >
        Sign Up
      </Button>
    </VStack>
  );
};

export default Signup;
